import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { useSearchParams, useNavigate, useLocation } from "react-router-dom";

import Sidebar from "../components/Sidebar";
import opportunities_logo from "../assets/opportunities_logo.png";
import filter_lines from "../assets/filter_lines.png";
import ActionSection from "../components/actionSection";
import brandSafety from "../json/brandSafety.json";
import dataSwitch from "../json/switch.json";
import NoActionsFound from "../components/noActionsFound";
import { RiDeleteBinLine } from "react-icons/ri";
import DialogComponent from "../components/dialogComponent";
import { toast } from "react-hot-toast";
import CustomToast from "../components/CustomToast";
import { IconType } from "react-icons";
import { FaCircleCheck } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { MdRestore } from "react-icons/md";
import { useAuth } from "../context/AuthContext";
import axiosInstance from "../utils/axiosInstance";
import Skeleton from "../components/Skeleton";
import {
  ChecksResponseProps,
  Checks,
  Data,
  FetchedFilterDataProps,
  SelectedFilterDataProps,
} from "../interfaces/brandSafety";
import UserAvatar from "../components/userAvatar/UserAvatar";
import Loader from "../components/statusIndicator/loader";
import RefreshChecksPopup from "../components/opportunities/RefreshChecksPopup";
import { getMatchingIds } from "../constants/functions";

const FilterPopup = lazy(() => import("../components/FilterPopup"));

const initialUrl = "/checks/all";

const Opportunities: React.FC = () => {
  const [allChecksData, setAllChecksData] =
    useState<ChecksResponseProps | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const campaignIds = searchParams.get("campaign_ids")?.split(",") || [];
  const [brandSafetyData, setBrandSafetyData] = useState<Checks[]>([]);
  const [nextBtnLink, setNextBtnLink] = useState<string | null>(null);
  const [previousBtnLink, setPreviousBtnLink] = useState<string | null>(null);

  const [queuedActionsData, setQueuedActionsData] = useState<Checks[]>([]);
  const [archiveActions, setArchiveActions] = useState<Checks[]>([]);
  const [selectedForDelete, setSelectedForDelete] = useState<any | null>(null);

  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [showPermanentlyDeletePopup, setShowPermanentlyDeletePopup] =
    useState<boolean>(false);

  const [showRestorePopup, setShowRestorePopup] = useState<boolean>(false);
  const [showMarkAsIrrelevantPopup, setShowMarkAsIrrelevantPopup] =
    useState<boolean>(false);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState<boolean>(false);
  const [userFeedback, setUserFeedback] = useState<string>("");
  const [showFilterPopup, setShowFilterPopup] = useState<boolean>(false);

  //selected from queue
  const [selectedFromQueue, setSelectedFromQueue] = useState<Checks[]>([]);

  //restore
  const [selectedForRestore, setSelectedForRestore] = useState<any | null>(
    null
  );

  const [urlLink, setUrlLink] = useState<string>(initialUrl);
  const [pageCount, setPageCount] = useState<string>("1");
  const [loadingBrandSafety, setLoadingBrandSafety] = useState<boolean>(false);
  const { accessToken, refreshToken, refreshAccessToken, fullName } = useAuth();
  const [allPartnersIds, setAllPartnersIds] = useState<string[]>([]);

  // New defined filter states
  const [fetchedFilterList, setFetchedFilterList] =
    useState<FetchedFilterDataProps>({} as FetchedFilterDataProps);

  const [appliedFilters, setAppliedFilters] = useState<SelectedFilterDataProps>(
    {} as SelectedFilterDataProps
  );

  const [prevFilterListLength, setPrevFilterListLength] = useState<number>(
    appliedFilters?.advertisers?.length || 0
  );

  const [prevCampaignIdLength, setPrevCampaignIdLength] = useState<number>(
    appliedFilters?.campaigns?.length || 0
  );

  const [prevBookingIdLength, setPrevBookingIdLength] = useState<number>(
    appliedFilters?.bookingIds?.length || 0
  );

  const [prevPlatform, setPreviousPlatform] = useState<
    string | null | undefined
  >(appliedFilters.check_dsps);

  //remove from the queued actions
  const [
    showRemoveFromQueuedActionsPopup,
    setShowRemoveFromQueuedActionsPopup,
  ] = useState<boolean>(false);

  const location = useLocation();
  const {
    advertiser,
    campaign,
    booking_id,
  }: { advertiser: string; campaign: string; booking_id: string } =
    location.state || {};

  // This useEffect is working with the view details button from campaign page
  useEffect(() => {
    if (advertiser && campaign && booking_id) {
      // Here we can add DSP type also, as this data is coming from campaigns page There is no need for now as the data we are showing is all data
      setAppliedFilters({
        advertisers: [advertiser],
        campaigns: [campaign],
        bookingIds: [booking_id],
      });
    }
  }, []);

  const updateQueryParams = (
    newAdvertiserIds: string[],
    newCampaignIds: string[],
    newBookingIds: string[],
    newPlatform: string | undefined
  ) => {
    const existingCampaignIds: string[] = getMatchingIds(newCampaignIds);

    // Prepare query parameters object
    const params: Record<string, string> = {
      advertiser_ids: newAdvertiserIds.join(","),
      campaign_ids: existingCampaignIds.join(","),
      booking_ids: newBookingIds.join(","),
    };

    if (newPlatform !== undefined) {
      params.check_dsps = newPlatform;
    }

    setSearchParams(params);
  };

  const removeQueryParams = () => {
    navigate(window.location.pathname, { replace: true });
  };

  const [showRefreshChecksPopup, setShowRefreshChecksPopup] =
    useState<boolean>(false);

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    }
  }, [accessToken, refreshToken, refreshAccessToken]);

  useEffect(() => {
    if (allChecksData) {
      setBrandSafetyData(allChecksData.results);
      setNextBtnLink(allChecksData.next);
      setPreviousBtnLink(allChecksData.previous);
    }
  }, [allChecksData, nextBtnLink]);

  const resetUrlLink = () => {
    setUrlLink(initialUrl);
    updateQueryParams([], [], [], undefined);
    setAppliedFilters({
      check_dsps: null,
      advertisers: [],
      campaigns: [],
      bookingIds: [],
    });
    removeQueryParams();
    setPageCount("1");
  };

  // This useEffect is only for the change in the page whenever there is a change in the filters applied count
  useEffect(() => {
    if (appliedFilters?.advertisers?.length !== prevFilterListLength) {
      setPageCount("1");
    } else if (appliedFilters?.campaigns?.length !== prevCampaignIdLength) {
      setPageCount("1");
    } else if (appliedFilters?.bookingIds?.length !== prevBookingIdLength) {
      setPageCount("1");
    } else if (appliedFilters?.check_dsps !== prevPlatform) {
      setPageCount("1");
    }
    setPrevFilterListLength(appliedFilters?.advertisers?.length);
    setPrevCampaignIdLength(appliedFilters?.campaigns?.length);
    setPrevBookingIdLength(appliedFilters?.bookingIds?.length);
    setPreviousPlatform(appliedFilters?.check_dsps);
  }, [
    appliedFilters,
    prevFilterListLength,
    prevCampaignIdLength,
    prevBookingIdLength,
    prevPlatform,
  ]);

  const handleLoadMoreBtn = (btnText: string) => {
    const getPageNumberFromUrl = (url: string): number | null => {
      try {
        const pageParam = new URL(url).searchParams.get("page");
        return pageParam ? Number(pageParam) : null;
      } catch (error) {
        console.error("Invalid URL provided:", url, error);
        return null;
      }
    };

    const updateUrlAndPageCount = (link: string) => {
      try {
        const relativeUrl = link.substring(link.indexOf("/checks"));
        setUrlLink(relativeUrl);

        const pageNumber = getPageNumberFromUrl(link);

        // Increment page count only if pageNumber is valid, else fallback to 1
        setPageCount(pageNumber !== null ? pageNumber.toString() : "1");
      } catch (error) {
        console.error("Error updating URL and page count:", error);
      }
    };

    const normalizedText = btnText.toLowerCase();

    if (normalizedText === "next" && nextBtnLink) {
      updateUrlAndPageCount(nextBtnLink);
    } else if (normalizedText === "previous" && previousBtnLink) {
      updateUrlAndPageCount(previousBtnLink);
    } else if (normalizedText === "first_page") {
      const cleanedUrl = urlLink
        .replace(/([&?])page=[^&]*/, "$1")
        .replace(/[\?&]$/, "");
      setUrlLink(cleanedUrl);
      setPageCount("1");
    } else {
      console.warn(`Unhandled button text: ${btnText}`);
    }
  };

  useEffect(() => {
    let isMounted = true;

    let matchingAdvertiserIds: string[] = [];
    let matchingCampaignIds: string[] = [];
    let matchingBookingIds: string[] = appliedFilters.bookingIds || [];
    let platform: string | null = appliedFilters.check_dsps || null;

    if (appliedFilters?.advertisers?.length > 0) {
      matchingAdvertiserIds = getMatchingIds(appliedFilters.advertisers);
    }
    if (appliedFilters?.campaigns?.length > 0) {
      matchingCampaignIds = getMatchingIds(appliedFilters.campaigns);
    }

    const getPageParam = (activeUrl: string | undefined): string => {
      if (!activeUrl) return "1";
      try {
        return new URL(activeUrl).searchParams.get("page") || "1";
      } catch (error) {
        console.error("Invalid URL:", activeUrl, error);
        return "1";
      }
    };

    const buildActiveLink = (): string => {
      const advertiserIdsParam = matchingAdvertiserIds.length
        ? `advertiser_ids=${matchingAdvertiserIds.join(",")}`
        : "";
      const campaignIdsParam = matchingCampaignIds.length
        ? `campaign_ids=${matchingCampaignIds.join(",")}`
        : "";
      const bookingIdsParam = matchingBookingIds.length
        ? `booking_ids=${matchingBookingIds.join(",")}`
        : "";
      const checkDspsParam = platform ? `check_dsps=${platform}` : "";
      const pageParam = `page=${getPageParam(
        `${window.origin}/api${urlLink}`
      )}`;

      const params = [
        advertiserIdsParam,
        campaignIdsParam,
        bookingIdsParam,
        checkDspsParam,
        pageParam,
      ]
        .filter(Boolean)
        .join("&");

      return params.length > 0 ? `${initialUrl}?${params}` : initialUrl;
    };

    let activeLink: string;
    if (
      appliedFilters?.advertisers?.length !== prevFilterListLength ||
      appliedFilters?.campaigns?.length !== prevCampaignIdLength ||
      appliedFilters?.bookingIds?.length !== prevBookingIdLength ||
      appliedFilters?.campaigns?.length > 0 ||
      appliedFilters?.check_dsps !== prevPlatform
    ) {
      activeLink = buildActiveLink();
    } else {
      const hasFilters =
        urlLink.includes("advertiser_ids=") ||
        urlLink.includes("campaign_ids=") ||
        urlLink.includes("booking_ids=") ||
        urlLink.includes("check_dsps=");
      activeLink =
        urlLink.includes("page=") || hasFilters ? urlLink : initialUrl;
    }

    const getAllOpportunitiesData = async () => {
      if (!accessToken || !refreshToken) {
        refreshAccessToken();
        return;
      }

      setLoadingBrandSafety(true);

      if (dataSwitch.use_demo_data_for_opportunities) {
        setAllChecksData(brandSafety.data);
        setLoadingBrandSafety(false);
      } else {
        try {
          const response = await axiosInstance.get(activeLink);
          if (response.status === 200 && isMounted) {
            setAllChecksData(response.data.data);
          } else {
            throw new Error("Failed to fetch brand safety data");
          }
        } catch (error: any) {
          if (isMounted) {
            toast.error(
              error.response?.data?.message ||
                "Error fetching brand safety data."
            );
          }
        } finally {
          if (isMounted) {
            setLoadingBrandSafety(false);
          }
        }
      }
    };

    getAllOpportunitiesData();

    return () => {
      isMounted = false;
    };
  }, [
    accessToken,
    refreshToken,
    urlLink,
    refreshAccessToken,
    appliedFilters.advertisers,
    appliedFilters.campaigns,
    appliedFilters.bookingIds,
    appliedFilters.check_dsps,
  ]);

  // Function to close the dialog
  const closeConfirmDeleteDialog = () => {
    setShowDeletePopup(false);
    setSelectedForDelete(null);
  };

  const closePermanentDeleteDialog = () => {
    setShowPermanentlyDeletePopup(false);
    setSelectedForDelete(null);
  };

  // Function to show a custom toast
  const showCustomToast = (
    backgroundColor: string,
    toastIcon: IconType,
    toastText: string,
    iconColor: string
  ) => {
    toast.custom(
      (t) => (
        <CustomToast
          backgroundColor={backgroundColor}
          toastIcon={toastIcon}
          toastText={toastText}
          iconColor={iconColor}
          t={t}
        />
      ),
      {
        duration: 3000,
      }
    );
  };

  const handleConfirmDelete = useCallback(() => {
    if (selectedForDelete) {
      setBrandSafetyData((prevData) =>
        prevData.filter((item) => item.id !== selectedForDelete.id)
      );
      setArchiveActions((prev) => [...prev, selectedForDelete]);
      showCustomToast(
        "#FFEAEA",
        RiDeleteBinLine,
        "Action archived successfully",
        "#F01F1F"
      );
    }
    setSelectedForDelete(null);
    closeConfirmDeleteDialog();
    if (showFeedbackPopup) {
      setShowFeedbackPopup(false);
      setUserFeedback("");
    }
  }, [selectedForDelete, showFeedbackPopup]);

  const handlePermanentDeleteFromArchive = () => {
    if (selectedForDelete) {
      setArchiveActions((prevData) =>
        prevData.filter((item) => item.id !== selectedForDelete.id)
      );
      showCustomToast(
        "#FFEAEA",
        RiDeleteBinLine,
        "Action deleted permanently",
        "#F01F1F"
      );
    }
    setSelectedForDelete(null);
    setShowPermanentlyDeletePopup(false);
  };

  const handleAddToQueue = useCallback(
    (item: any) => {
      if (!queuedActionsData.some((queuedItem) => queuedItem.id === item.id)) {
        setQueuedActionsData((prevData) => [...prevData, item]);
        setBrandSafetyData((prevData) =>
          prevData.filter((existingItem) => existingItem.id !== item.id)
        );
        setSelectedFromQueue((prevData) => [...prevData, item]);
        showCustomToast(
          "#EAFFF6",
          FaCircleCheck,
          "Successfully added to queue",
          "#2DC485"
        );
      }
    },
    [queuedActionsData]
  );

  const handleRestore = (item: any) => {
    setSelectedForRestore(item);
    setShowRestorePopup(true);
  };

  const closeRestoreDialog = () => {
    setShowRestorePopup(false);
    setSelectedForDelete(null);
  };

  const handleConfirmRestore = () => {
    setBrandSafetyData((prevData) => [...prevData, selectedForRestore]);
    setArchiveActions((prevData) =>
      prevData.filter(
        (existingItem) => existingItem.id !== selectedForRestore.id
      )
    );
    showCustomToast(
      "#EAFFF6",
      FaCircleCheck,
      "Action restored successfully",
      "#2DC485"
    );
    closeRestoreDialog();
  };

  const closeMarkAsIrrelevantPopup = () => {
    setShowMarkAsIrrelevantPopup(false);
  };

  const handleMarkAsIrrelevant = () => {
    setShowMarkAsIrrelevantPopup(false);
    setShowFeedbackPopup(true);
  };

  const handleMarkAsIrrelevantPopup = () => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className="bg-white shadow-lg w-[413px] border border-gray-300"
        style={{
          boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
        }}
      >
        <div className="py-6 px-8">
          <button
            className="py-[6px] pr-[6px]"
            onClick={() => {
              setShowFeedbackPopup(false);
              setSelectedForDelete(null);
            }}
          >
            <IoClose />
          </button>
          <div className="mt-[10px] flex flex-col">
            <p className="text-base font-semibold">
              Please provide feedback to help us improve our recommendations
            </p>
            <textarea
              className="border-[0.5px] my-4 border-gray-300 h-[187px] p-1 text-sm"
              value={userFeedback}
              onChange={(e) => setUserFeedback(e.target.value)}
            />
            <button
              className={`border ${
                userFeedback?.length
                  ? "bg-[#286043] border-[#286043]"
                  : "bg-gray-300 border-gray-300"
              } p-3 flex justify-center items-center gap-1 text-sm leading-4 text-left font-[600]  text-white`}
              onClick={handleConfirmDelete}
              disabled={userFeedback?.length ? false : true}
            >
              Send Feedback
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  // Open the filter popup
  const handleFilter = () => {
    setShowFilterPopup(true);
  };

  // Close the filter popup
  const closeFilterPopup = () => {
    setShowFilterPopup(false);
  };

  const handleClearAllFilters = () => {
    resetUrlLink();
  };

  // remove the applied filters
  const removeFilterOneByOne = (item: string) => {
    if (!item.includes(":")) {
      console.error("Invalid filter format:", item);
      return;
    }

    const [advertiserLevel, selectedItem] = item
      .split(":")
      .map((str) => str.trim());

    let camResult = [...appliedFilters.campaigns];
    let advResult = [...appliedFilters.advertisers];
    let bookingResult = [...appliedFilters.bookingIds];
    let platform = appliedFilters.check_dsps;

    if (advertiserLevel.toLowerCase() === "campaign") {
      camResult = camResult.filter((element) => element !== selectedItem);
    } else if (advertiserLevel.toLowerCase() === "advertiser") {
      advResult = advResult.filter((element) => element !== selectedItem);
    } else if (advertiserLevel.toLowerCase() === "booking id") {
      bookingResult = bookingResult.filter(
        (element) => element.trim() !== selectedItem.trim()
      );
    } else if (advertiserLevel.toLowerCase() === "platform") {
      platform = null;
    } else {
      console.error("Invalid level:", advertiserLevel);
      return;
    }

    if (
      camResult.length === 0 &&
      advResult.length === 0 &&
      bookingResult.length === 0 &&
      appliedFilters.check_dsps == null // Check for null or undefined
    ) {
      resetUrlLink();
      return;
    }

    // Update state with the current filters
    setAppliedFilters({
      advertisers: advResult,
      campaigns: camResult,
      bookingIds: bookingResult,
      check_dsps: platform, // Keep check_dsps based on platform if it's reset
    });

    // Update query params based on the new filters
    updateQueryParams(advResult, camResult, bookingResult, undefined);
  };

  const handleRemoveFromQueuedActionList = () => {
    const newQueuedActionsData = queuedActionsData.filter(
      (item) => !selectedFromQueue.some((selected) => selected.id === item.id)
    );
    const newBrandSafetyData = [...brandSafetyData, ...selectedFromQueue];
    setQueuedActionsData(newQueuedActionsData);
    setBrandSafetyData(newBrandSafetyData);
    setSelectedFromQueue([]);
    showCustomToast(
      "#FFEAEA",
      RiDeleteBinLine,
      "Action removed successfully",
      "#F01F1F"
    );
    closeRemoveFromQueuedActions();
  };

  const closeRemoveFromQueuedActions = () => {
    setShowRemoveFromQueuedActionsPopup(false);
  };

  const hasFetched = useRef(false);

  // Data fetching for filters
  useEffect(() => {
    if (hasFetched.current) return;

    const getAllDataForFilter = async () => {
      try {
        const advertisersPromises = allPartnersIds.map(async (id) => {
          const response = await axiosInstance.get(`/checks/get-filter-data`);

          if (response.status === 200) {
            return response.data.data;
          } else {
            throw new Error("Unexpected response while fetching advertisers");
          }
        });

        const results: any = await Promise.all(advertisersPromises);
        const allAdvertisers = results[0]["advertisers_data"].flat();
        const allCampaignData = results[0]["campaigns_data"].flat();
        const allBookingData = results[0]["booking_ids"];
        const allPlatforms = results[0]["check_dsps"];

        setFetchedFilterList({
          advertisers: allAdvertisers,
          campaigns: allCampaignData,
          bookingIds: allBookingData,
          checkDsps: allPlatforms,
        });
      } catch (error: any) {
        console.error("Error fetching advertisers:", error);

        toast.error(
          error?.response?.data?.message ||
            error.message ||
            "Error fetching advertisers, please try again."
        );
      } finally {
        hasFetched.current = true;
      }
    };

    // If allPartnersIds exists and isn't empty, proceed with the fetch
    if (allPartnersIds.length > 0) {
      getAllDataForFilter();
    }
  }, [allPartnersIds]);

  useEffect(() => {
    if (allChecksData) {
      const uniquePartnerIds: string[] = [];

      allChecksData?.results?.forEach((item) => {
        const partnerId = item.campaign_checks.partner_id;

        if (partnerId && !uniquePartnerIds.includes(partnerId)) {
          uniquePartnerIds.push(partnerId);
        }
      });

      if (uniquePartnerIds.length > 0) {
        setAllPartnersIds(uniquePartnerIds);
      }
    }
  }, [allChecksData]);

  function formatObjectData(data: Data): string[] {
    const result: string[] = [];

    function addValue(
      key: string,
      value: string | string[] | undefined | null
    ) {
      if (value && (Array.isArray(value) ? value.length > 0 : true)) {
        if (Array.isArray(value)) {
          value.forEach((item) => result.push(`${key} : ${item}`));
        } else {
          result.push(`${key} : ${value}`);
        }
      }
    }

    const mappings: { [key: string]: string } = {
      advertisers: "Advertiser",
      campaigns: "Campaign",
      booking_ids: "Booking ID",
      timeFrame: "Date Range",
      analysisCategory: "Analysis Category",
      severity: "Criticality",
      check_dsps: "Platform",
    };

    // Iterate through the mappings and call addValue for each key
    for (const [key, displayName] of Object.entries(mappings)) {
      const value = data[key as keyof Data];
      if (value !== undefined) {
        addValue(displayName, value);
      }
    }

    return result;
  }

  // to display all the applied filters
  const renderAllFilters = () => {
    const newFilters = formatObjectData({
      check_dsps: appliedFilters.check_dsps,
      advertisers: appliedFilters.advertisers,
      campaigns: appliedFilters.campaigns,
      booking_ids: appliedFilters.bookingIds,
    });
    return newFilters.map((item, index) => (
      <li
        key={index}
        className="border-[0.5px] p-1 text-xs font-normal flex items-center gap-1"
      >
        {item}{" "}
        <button onClick={() => removeFilterOneByOne(item)}>
          <IoClose />
        </button>
      </li>
    ));
  };

  const handleCloseRefreshPopup = () => {
    setShowRefreshChecksPopup(false);
  };

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="opportunities" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        {/* Opportunities Section */}
        {fullName && <UserAvatar />}
        <div className="flex justify-start items-center gap-4 mt-4">
          <div
            className="w-11 h-11 border border-solid flex justify-center items-center bg-[#E4FAFF]"
            style={{
              borderWidth: "0.5px",
              borderImageSource:
                "linear-gradient(141.57deg, #47F1FC 7.54%, rgba(93, 220, 248, 0) 102.53%)",
              borderImageSlice: 1,
            }}
          >
            <img
              src={opportunities_logo}
              alt="opportunities logo"
              className="w-[17.5px] h-[17.5px]"
            />
          </div>
          <div>
            <p className="text-xl font-[700] leading-6 text-left">
              Opportunities
            </p>
            <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
              keep your opportunities on track
            </p>
          </div>
        </div>

        {/* include all users & filter section */}
        <div
          className={`border border-l-0 border-r-0 border-t-[0.3px] border-b-[0.3px] mt-6 py-4 flex ${
            appliedFilters?.advertisers?.length > 0 ||
            appliedFilters?.campaigns?.length > 0 ||
            appliedFilters?.bookingIds?.length > 0 ||
            appliedFilters?.check_dsps !== null
              ? "justify-between"
              : "justify-end"
          } items-center`}
        >
          {(appliedFilters?.advertisers?.length > 0 ||
            appliedFilters?.campaigns?.length > 0 ||
            appliedFilters?.bookingIds?.length > 0 ||
            appliedFilters?.check_dsps !== null) && (
            <ul className="flex items-center gap-2 flex-wrap">
              {renderAllFilters()}
            </ul>
          )}
          <div className="flex items-center gap-2">
            <button
              className="border border-[#286043] p-3 flex justify-center items-center gap-1"
              onClick={handleFilter}
            >
              <p className="text-sm leading-4 text-left font-semibold text-[#286043]">
                Filter
              </p>
              <img src={filter_lines} alt="three_lines" className="w-[15px]" />
            </button>
            {(appliedFilters?.check_dsps?.trim() ||
              appliedFilters?.advertisers?.length > 0 ||
              appliedFilters?.campaigns?.length > 0 ||
              appliedFilters?.bookingIds?.length > 0) && (
              <button
                className="text-sm font-semibold text-[#286043] w-20"
                onClick={handleClearAllFilters}
              >
                Clear All
              </button>
            )}
          </div>
        </div>

        {/* Queued Section */}
        {queuedActionsData.length > 0 && (
          <ActionSection
            sectionName="Queued Actions"
            actionData={queuedActionsData}
            dataFor="Queued Actions"
            setQueuedActionsData={setQueuedActionsData}
            setSelectedForDelete={setSelectedForDelete}
            onMarkAsIrrelevant={setShowMarkAsIrrelevantPopup}
            selectedFromQueue={selectedFromQueue}
            setSelectedFromQueue={setSelectedFromQueue}
            queuedActionsData={queuedActionsData}
            setShowRemoveFromQueuedActionsPopup={
              setShowRemoveFromQueuedActionsPopup
            }
          />
        )}

        {/* Opportunities Section */}
        {loadingBrandSafety ? (
          <Skeleton />
        ) : brandSafetyData.length > 0 ? (
          <ActionSection
            sectionName="Opportunities"
            actionData={brandSafetyData}
            dataFor="Brand Safety"
            setQueuedActionsData={setQueuedActionsData}
            setShowDeletePopup={setShowDeletePopup}
            setSelectedForDelete={setSelectedForDelete}
            onAddToQueue={handleAddToQueue}
            onMarkAsIrrelevant={setShowMarkAsIrrelevantPopup}
            nextBtnLink={nextBtnLink}
            previousBtnLink={previousBtnLink}
            handleLoadMoreBtn={handleLoadMoreBtn}
            pageCount={pageCount}
            totalRowsCount={allChecksData?.count}
            setShowRefreshChecksPopup={setShowRefreshChecksPopup}
          />
        ) : (
          <NoActionsFound
            sectionName="Opportunities"
            errorHeading="No actions found!"
            errorDescription="No actions are currently available. Please check back later for updates."
          />
        )}

        {/* Dialog Modals */}
        {showDeletePopup && (
          <DialogComponent
            closeDialog={closeConfirmDeleteDialog}
            dialog_logo={RiDeleteBinLine}
            dialog_heading="Are you sure you want to delete this action?"
            dialogSuccessAction={handleConfirmDelete}
            successBtnText="Yes, Delete it"
            failureBtnText="No, cancel"
            showNote={true}
            action_type="delete"
          />
        )}
        {showPermanentlyDeletePopup && (
          <DialogComponent
            closeDialog={closePermanentDeleteDialog}
            dialog_logo={RiDeleteBinLine}
            dialog_heading="Are you sure you want to permanently delete this action?"
            dialogSuccessAction={handlePermanentDeleteFromArchive}
            successBtnText="Yes, Delete it"
            failureBtnText="No, cancel"
            showNote={false}
            action_type="delete"
          />
        )}
        {showMarkAsIrrelevantPopup && (
          <DialogComponent
            closeDialog={closeMarkAsIrrelevantPopup}
            dialog_logo={RiDeleteBinLine}
            dialog_heading="Are you sure you want to mark it as irrelevant?"
            dialogSuccessAction={handleMarkAsIrrelevant}
            successBtnText="Yes, Mark it"
            failureBtnText="No, cancel"
            showNote={true}
            action_type="delete"
          />
        )}

        {showRestorePopup && (
          <DialogComponent
            closeDialog={closeRestoreDialog}
            dialog_logo={MdRestore}
            dialog_heading="Are you sure you want to restore this action?"
            dialogSuccessAction={handleConfirmRestore}
            successBtnText="Yes, Restore it"
            failureBtnText="No, cancel"
            showNote={false}
            action_type="restore"
          />
        )}
        {showRemoveFromQueuedActionsPopup && (
          <DialogComponent
            closeDialog={closeRemoveFromQueuedActions}
            dialog_logo={RiDeleteBinLine}
            dialog_heading="Are you sure you want to remove this action?"
            dialogSuccessAction={handleRemoveFromQueuedActionList}
            successBtnText="Yes, Remove it"
            failureBtnText="No, cancel"
            showNote={false}
            action_type="delete"
          />
        )}
        {showFeedbackPopup && handleMarkAsIrrelevantPopup()}
        {showRefreshChecksPopup && (
          <RefreshChecksPopup
            closePopup={handleCloseRefreshPopup}
            partnersIds={allPartnersIds}
          />
        )}
        <Suspense fallback={<Loader />}>
          {showFilterPopup && (
            <FilterPopup
              isOpen={showFilterPopup}
              onClose={closeFilterPopup}
              fetchedFilterList={fetchedFilterList}
              resetUrlLink={resetUrlLink}
              appliedFilters={appliedFilters}
              setAppliedFilters={setAppliedFilters}
            />
          )}
        </Suspense>
      </div>
    </div>
  );
};

export default Opportunities;
