import React, { useEffect, useState, useRef } from "react";
import { useAuth } from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import UserAvatar from "../components/userAvatar/UserAvatar";
import NoActionsFound from "../components/noActionsFound";
import { TbSpacingVertical, TbChevronDown, TbChevronRight } from "react-icons/tb";
import SkeletonTable from "../components/Skeleton";
import axiosInstance from '../utils/axiosInstance';
import { get_logo_url } from '../utils/getConnectorsLogo';

// Define interfaces for the API response
interface Metrics {
  pacing_percentage: number;
  time_elapsed_percentage: number;
  budget_delivered_percentage: number;
  quantity_delivered_percentage: number;
  daily_impressions_required: number;
  clicks: number;
  cost: number;
  cpm: number;
  cpc: number;
  ctr: number;
  conversions: number;
  cpa: number;
  Complete_Views_Video: number;
  trueview_views: number;
  viewability: number;
}

interface Timeline {
  days_passed: number;
  days_left: number;
  total_days: number;
}

interface Delivery {
  impressions_delivered: number;
  impressions_goal: number;
  budget_delivered: number;
  budget_goal: number;
}

interface InsertionOrder {
  insertion_order_id: number;
  insertion_order_name: string;
  campaign_id: number;
  campaign_name: string;
  platform: string;
}

interface Deal {
  deal_id: string;
  trader: string;
  insertion_orders: InsertionOrder[];
  timeline: Timeline;
  metrics: Metrics;
  delivery: Delivery;
  status: 'on_track' | 'under_pacing' | 'over_pacing';
  start_date: string;
  end_date: string;
}

interface ApiResponse {
  data: {
    timestamp: string;
    total_count: number;
    deals: Deal[];
  };
}

interface FilterState {
  platform: string;
  campaign_id: string;
  status: string;
  deal_id: string;
}

interface SortState {
  sort_by: string;
  sort_order: 'asc' | 'desc';
}

interface SearchableSelectProps {
  options: Array<{ value: string | number; label: string }>;
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  className?: string;
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({
  options,
  value,
  onChange,
  placeholder,
  className = ''
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const filteredOptions = options.filter(option => 
    option.label.toLowerCase().includes(search.toLowerCase()) ||
    option.value.toString().toLowerCase().includes(search.toLowerCase())
  );

  const selectedOption = options.find(option => option.value.toString() === value);

  return (
    <div ref={wrapperRef} className={`relative ${className}`}>
      <div
        className="w-full border rounded-md shadow-sm p-2 cursor-pointer bg-white flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="truncate">
          {selectedOption ? selectedOption.label : placeholder}
        </span>
        <span className="text-gray-400">▼</span>
      </div>
      
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto">
          <div className="sticky top-0 bg-white p-2 border-b">
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="w-full border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Search..."
              onClick={(e) => e.stopPropagation()}
              autoFocus
            />
          </div>
          
          <div className="py-1">
            <div
              className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => {
                onChange('');
                setIsOpen(false);
                setSearch('');
              }}
            >
              {placeholder}
            </div>
            {filteredOptions.map((option) => (
              <div
                key={option.value}
                className={`px-3 py-2 cursor-pointer hover:bg-gray-100 ${
                  value === option.value.toString() ? 'bg-blue-50' : ''
                }`}
                onClick={() => {
                  onChange(option.value.toString());
                  setIsOpen(false);
                  setSearch('');
                }}
              >
                {option.label}
              </div>
            ))}
            {filteredOptions.length === 0 && (
              <div className="px-3 py-2 text-gray-500 italic">
                No results found
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const SORT_OPTIONS = [
  { value: 'pacing', label: 'Pacing' },
  { value: 'budget_delivered', label: 'Budget Delivered %' },
  { value: 'impressions_delivered', label: 'Impressions Delivered %' }
];

const STATUS_OPTIONS = [
  { value: 'on_track', label: 'On Track' },
  { value: 'under_pacing', label: 'Under Pacing' },
  { value: 'over_pacing', label: 'Over Pacing' }
];

const PLATFORM_OPTIONS = [
  { value: 'google_ads', label: 'Google Ads' },
  { value: 'meta_ads', label: 'Meta Ads' },
  { value: 'fb_ads', label: 'FB Ads' },
  { value: 'dv360', label: 'DV360' },
  { value: 'ttd', label: 'The Trade Desk' },
  { value: 'vistar', label: 'Vistar' },
  { value: 'google_sheets', label: 'Google Sheets' },
  { value: 'double_verify', label: 'Double Verify' }
];

const PacingMargin: React.FC = () => {
  const [deals, setDeals] = useState<Deal[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [expandedDeals, setExpandedDeals] = useState<Set<string>>(new Set());
  const { accessToken, refreshToken, refreshAccessToken, fullName } = useAuth();
  const [filters, setFilters] = useState<FilterState>({
    platform: '',
    campaign_id: '',
    status: '',
    deal_id: ''
  });
  const [sort, setSort] = useState<SortState>({
    sort_by: '',
    sort_order: 'asc'
  });
  const [campaigns, setCampaigns] = useState<Array<{ id: number; name: string }>>([]);
  const [dealIds, setDealIds] = useState<Array<{ deal_id: string }>>([]);
  const [selectedMetrics, setSelectedMetrics] = useState<string[]>([]);
  const [isMetricSelectorOpen, setIsMetricSelectorOpen] = useState(false);
  const metricDropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    } else {
      fetchDealData();
    }
  }, [accessToken, refreshToken, refreshAccessToken, filters, sort]);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const [campaignsResponse, dealsResponse] = await Promise.all([
          axiosInstance.get('/alerts/entities/campaigns/'),
          axiosInstance.get('/alerts/entities/deals/')
        ]);
        setCampaigns(campaignsResponse.data.data || []);
        setDealIds(dealsResponse.data.data || []);
      } catch (error) {
        console.error('Failed to fetch filter options:', error);
      }
    };
    fetchFilterOptions();
  }, []);

  // Add click outside handler for metric dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (metricDropdownRef.current && !metricDropdownRef.current.contains(event.target as Node)) {
        setIsMetricSelectorOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const fetchDealData = async () => {
    try {
      setLoading(true);
      
      // Build query parameters
      const queryParams = new URLSearchParams();
      
      // Add filters
      if (filters.platform) queryParams.append('platform', filters.platform);
      if (filters.campaign_id) queryParams.append('campaign_id', filters.campaign_id);
      if (filters.status) queryParams.append('status', filters.status);
      if (filters.deal_id) queryParams.append('deal_id', filters.deal_id);
      
      // Add sorting
      if (sort.sort_by) {
        queryParams.append('sort_by', sort.sort_by);
        queryParams.append('sort_order', sort.sort_order);
      }

      const response = await axiosInstance.get<ApiResponse>(
        `/alerts/entities/active_deals_pacing/?${queryParams.toString()}`
      );
      setDeals(response.data.data.deals);
    } catch (error) {
      console.error('Failed to fetch deal data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const [campaignsResponse, dealsResponse] = await Promise.all([
          axiosInstance.get('/alerts/entities/campaigns/'),
          axiosInstance.get('/alerts/entities/deals/')
        ]);
        setCampaigns(campaignsResponse.data.data || []);
        setDealIds(dealsResponse.data.data || []);
      } catch (error) {
        console.error('Failed to fetch filter options:', error);
      }
    };
    fetchFilterOptions();
  }, []);

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP'
    }).format(value);
  };

  const formatPercentage = (value: number) => {
    return `${value.toFixed(2)}%`;
  };

  const formatNumber = (value: number) => {
    return new Intl.NumberFormat('en-GB').format(value);
  };

  const getStatusColor = (status: Deal['status']) => {
    switch (status) {
      case 'on_track':
        return {
          bg: 'bg-green-100',
          text: 'text-green-800',
          bar: 'bg-green-500'
        };
      case 'under_pacing':
        return {
          bg: 'bg-yellow-100',
          text: 'text-yellow-800',
          bar: 'bg-yellow-500'
        };
      case 'over_pacing':
        return {
          bg: 'bg-red-100',
          text: 'text-red-800',
          bar: 'bg-red-500'
        };
      default:
        // Default color for unknown status or undefined
        return {
          bg: 'bg-gray-100',
          text: 'text-gray-800',
          bar: 'bg-gray-500'
        };
    }
  };

  const getStatusDisplay = (status: Deal['status']) => {
    switch (status) {
      case 'on_track':
        return 'On Track';
      case 'under_pacing':
        return 'Under Pacing';
      case 'over_pacing':
        return 'Over Pacing';
      default:
        return 'Unknown Status';
    }
  };

  const toggleDealExpansion = (dealId: string) => {
    setExpandedDeals(prev => {
      const newSet = new Set(prev);
      if (newSet.has(dealId)) {
        newSet.delete(dealId);
      } else {
        newSet.add(dealId);
      }
      return newSet;
    });
  };

  const getDistinctPlatforms = (insertionOrders: InsertionOrder[]) => {
    const platforms = new Set(insertionOrders.map(io => io.platform));
    return Array.from(platforms);
  };

  const AVAILABLE_METRICS = [
    { value: 'clicks', label: 'Clicks' },
    { value: 'cost', label: 'Cost' },
    { value: 'cpm', label: 'CPM' },
    { value: 'cpc', label: 'CPC' },
    { value: 'ctr', label: 'CTR' },
    { value: 'conversions', label: 'Conversions' },
    { value: 'cpa', label: 'CPA' },
    { value: 'Complete_Views_Video', label: 'Complete Video Views' },
    { value: 'trueview_views', label: 'Trueview Views' },
    { value: 'viewability', label: 'Viewability' }
  ];

  const formatMetricValue = (metric: string, value: number) => {
    switch (metric) {
      case 'cost':
      case 'cpa':
      case 'cpm':
      case 'cpc':
        return formatCurrency(value);
      case 'ctr':
        return formatPercentage(value);
      default:
        return formatNumber(value);
    }
  };

  const renderFiltersAndSort = () => (
    <div className="mb-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
      {/* Platform Filter */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Platform</label>
        <select
          value={filters.platform}
          onChange={(e) => setFilters(prev => ({ ...prev, platform: e.target.value }))}
          className="w-full border rounded-md shadow-sm p-2"
        >
          <option value="">All Platforms</option>
          {PLATFORM_OPTIONS.map(platform => (
            <option key={platform.value} value={platform.value}>
              {platform.label}
            </option>
          ))}
        </select>
      </div>

      {/* Campaign Filter */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Campaign</label>
        <SearchableSelect
          options={campaigns.map(campaign => ({
            value: campaign.id,
            label: `${campaign.name} (${campaign.id})`
          }))}
          value={filters.campaign_id}
          onChange={(value) => setFilters(prev => ({ ...prev, campaign_id: value }))}
          placeholder="All Campaigns"
        />
      </div>

      {/* Status Filter */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
        <select
          value={filters.status}
          onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
          className="w-full border rounded-md shadow-sm p-2"
        >
          <option value="">All Statuses</option>
          {STATUS_OPTIONS.map(status => (
            <option key={status.value} value={status.value}>
              {status.label}
            </option>
          ))}
        </select>
      </div>

      {/* Deal ID Filter */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Deal ID</label>
        <SearchableSelect
          options={dealIds.map(deal => ({
            value: deal.deal_id,
            label: `Deal ID: ${deal.deal_id}`
          }))}
          value={filters.deal_id}
          onChange={(value) => setFilters(prev => ({ ...prev, deal_id: value }))}
          placeholder="All Deals"
        />
      </div>

      {/* Sort Options */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Sort By</label>
        <div className="flex gap-2">
          <select
            value={sort.sort_by}
            onChange={(e) => setSort(prev => ({ ...prev, sort_by: e.target.value }))}
            className="flex-1 border rounded-md shadow-sm p-2"
          >
            <option value="">None</option>
            {SORT_OPTIONS.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {sort.sort_by && (
            <button
              onClick={() => setSort(prev => ({
                ...prev,
                sort_order: prev.sort_order === 'asc' ? 'desc' : 'asc'
              }))}
              className="px-3 py-2 border rounded-md"
            >
              {sort.sort_order === 'asc' ? '↑' : '↓'}
            </button>
          )}
        </div>
      </div>

      {/* Add Metric Column Button - Now outside the grid */}
      <div className="col-span-full mt-4">
        <div ref={metricDropdownRef} className="relative inline-block">
          <button
            onClick={() => setIsMetricSelectorOpen(!isMetricSelectorOpen)}
            className="flex items-center justify-between px-4 py-2 border rounded-md shadow-sm bg-white hover:bg-gray-50"
          >
            <span>Add Metric Columns</span>
            <TbChevronDown 
              className={`ml-2 transform transition-transform ${isMetricSelectorOpen ? 'rotate-180' : ''}`}
            />
          </button>
          
          {isMetricSelectorOpen && (
            <div className="absolute z-50 mt-1 w-64 bg-white border rounded-md shadow-lg max-h-60 overflow-auto">
              {AVAILABLE_METRICS.map((metric) => (
                <div
                  key={metric.value}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedMetrics(prev => 
                      prev.includes(metric.value)
                        ? prev.filter(m => m !== metric.value)
                        : [...prev, metric.value]
                    );
                  }}
                >
                  <input
                    type="checkbox"
                    checked={selectedMetrics.includes(metric.value)}
                    onChange={() => {}}
                    className="mr-2"
                  />
                  {metric.label}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const renderTableHeaders = () => (
    <tr>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Deal & Insertion Orders</th>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Campaign</th>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Platform</th>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Duration</th>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Pacing</th>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Budget</th>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Impressions</th>
      {selectedMetrics.map((metric) => (
        <th key={metric} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          {AVAILABLE_METRICS.find(m => m.value === metric)?.label}
        </th>
      ))}
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Trader</th>
    </tr>
  );

  const renderDealRow = (deal: Deal) => (
    <>
      <td className="px-6 py-4">
        <div className="flex items-center space-x-2">
          {expandedDeals.has(deal.deal_id) ? (
            <TbChevronDown className="text-gray-500 flex-shrink-0" />
          ) : (
            <TbChevronRight className="text-gray-500 flex-shrink-0" />
          )}
          <div>
            <div className="text-sm font-medium text-gray-900">Deal ID: {deal.deal_id}</div>
            <div className="text-xs text-gray-500">
              {deal.insertion_orders.length} Insertion Order{deal.insertion_orders.length !== 1 ? 's' : ''}
            </div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4">
        <div className="max-w-[500px] break-words">
          <div className="text-sm font-medium text-gray-900">
            {deal.insertion_orders[0]?.campaign_name}
          </div>
          <div className="text-sm text-gray-500">ID: {deal.insertion_orders[0]?.campaign_id}</div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex flex-col gap-2">
          {getDistinctPlatforms(deal.insertion_orders).map((platform, index) => (
            <div key={index} className="flex items-center gap-2">
              {get_logo_url(platform) && (
                <img 
                  src={get_logo_url(platform)} 
                  alt={`${platform} logo`} 
                  className="w-5 h-5 object-contain"
                />
              )}
              <div className="text-sm font-medium text-gray-900">
                {platform}
              </div>
            </div>
          ))}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex flex-col space-y-2">
          <div>
            <div className="text-xs font-medium text-gray-500 uppercase mb-1">Campaign Period</div>
            <div className="text-sm text-gray-900">
              {new Date(deal.start_date).toLocaleDateString()} - {new Date(deal.end_date).toLocaleDateString()}
            </div>
          </div>
          <div>
            <div className="text-xs font-medium text-gray-500 uppercase mb-1">Timeline</div>
            <div className="flex items-center space-x-2">
              <div className="h-1.5 flex-grow bg-gray-200 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-blue-500"
                  style={{ width: `${(deal.timeline.days_passed / deal.timeline.total_days) * 100}%` }}
                />
              </div>
              <span className="text-sm font-medium text-gray-900 whitespace-nowrap">
                {deal.timeline.days_left}d left
              </span>
            </div>
            <div className="text-xs text-gray-500 mt-1">
              {deal.timeline.days_passed}/{deal.timeline.total_days} days completed
            </div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">
          {formatPercentage(deal.metrics.pacing_percentage)}
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
          <div 
            className={`h-2.5 rounded-full ${getStatusColor(deal.status).bar}`}
            style={{ width: `${Math.min(100, deal.metrics.pacing_percentage)}%` }}
          ></div>
        </div>
        <div className="text-xs text-gray-500 mt-1">
          Target: {formatNumber(deal.metrics.daily_impressions_required)} impressions/day
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">
          {formatCurrency(deal.delivery.budget_delivered)} / {formatCurrency(deal.delivery.budget_goal)}
        </div>
        <div className="text-sm text-gray-500">
          {formatPercentage(deal.metrics.budget_delivered_percentage)} delivered
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">
          {formatNumber(deal.delivery.impressions_delivered)} / {formatNumber(deal.delivery.impressions_goal)}
        </div>
        <div className="text-sm text-gray-500">
          {formatPercentage(deal.metrics.quantity_delivered_percentage)} delivered
        </div>
      </td>
      {selectedMetrics.map((metric) => (
        <td key={metric} className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">
            {formatMetricValue(metric, deal.metrics[metric as keyof Metrics])}
          </div>
        </td>
      ))}
      <td className="px-6 py-4 whitespace-nowrap">
        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
          getStatusColor(deal.status).bg} ${getStatusColor(deal.status).text
        }`}>
          {getStatusDisplay(deal.status)}
        </span>
      </td>
    </>
  );

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="pacing & margin" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        {fullName && <UserAvatar />}
        {/* Heading Section */}
        <div className="flex justify-start items-center gap-4 mt-4 border-b-[0.75px] pb-[21px]">
          <div
            className="w-11 h-11 border border-solid flex justify-center items-center bg-[#E4FAFF]"
            style={{
              borderWidth: "0.5px",
              borderImageSource:
                "linear-gradient(141.57deg, #47F1FC 7.54%, rgba(93, 220, 248, 0) 102.53%)",
              borderImageSlice: 1,
            }}
          >
            <TbSpacingVertical size={20} />
          </div>
          <div>
            <p className="text-xl font-[700] leading-6 text-left">
              Deal Pacing
            </p>
            <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
              Monitor delivery and pacing for active deals and insertion orders
            </p>
          </div>
        </div>

        {/* Filters and Sort Section */}
        {renderFiltersAndSort()}

        {/* Table Section */}
        {loading ? (
          <SkeletonTable />
        ) : deals.length > 0 ? (
          <div className="mt-6 overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                {renderTableHeaders()}
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {deals.map((deal) => (
                  <React.Fragment key={deal.deal_id}>
                    <tr 
                      className="bg-gray-50 hover:bg-gray-100 transition-colors cursor-pointer"
                      onClick={() => toggleDealExpansion(deal.deal_id)}
                    >
                      <td className="px-6 py-4">
                        <div className="flex items-center space-x-2">
                          {expandedDeals.has(deal.deal_id) ? (
                            <TbChevronDown className="text-gray-500 flex-shrink-0" />
                          ) : (
                            <TbChevronRight className="text-gray-500 flex-shrink-0" />
                          )}
                          <div>
                            <div className="text-sm font-medium text-gray-900">Deal ID: {deal.deal_id}</div>
                            <div className="text-xs text-gray-500">
                              {deal.insertion_orders.length} Insertion Order{deal.insertion_orders.length !== 1 ? 's' : ''}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className="max-w-[500px] break-words">
                          <div className="text-sm font-medium text-gray-900">{deal.insertion_orders[0]?.campaign_name}</div>
                          <div className="text-sm text-gray-500">ID: {deal.insertion_orders[0]?.campaign_id}</div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex flex-col gap-2">
                          {getDistinctPlatforms(deal.insertion_orders).map((platform, index) => (
                            <div key={index} className="flex items-center gap-2">
                              {get_logo_url(platform) && (
                                <img 
                                  src={get_logo_url(platform)} 
                                  alt={`${platform} logo`} 
                                  className="w-5 h-5 object-contain"
                                />
                              )}
                              <div className="text-sm font-medium text-gray-900">{platform}</div>
                            </div>
                          ))}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex flex-col space-y-2">
                          <div>
                            <div className="text-xs font-medium text-gray-500 uppercase mb-1">Campaign Period</div>
                            <div className="text-sm text-gray-900">
                              {new Date(deal.start_date).toLocaleDateString()} - {new Date(deal.end_date).toLocaleDateString()}
                            </div>
                          </div>
                          <div>
                            <div className="text-xs font-medium text-gray-500 uppercase mb-1">Timeline</div>
                            <div className="flex items-center space-x-2">
                              <div className="h-1.5 flex-grow bg-gray-200 rounded-full overflow-hidden">
                                <div 
                                  className="h-full bg-blue-500"
                                  style={{ width: `${(deal.timeline.days_passed / deal.timeline.total_days) * 100}%` }}
                                />
                              </div>
                              <span className="text-sm font-medium text-gray-900 whitespace-nowrap">
                                {deal.timeline.days_left}d left
                              </span>
                            </div>
                            <div className="text-xs text-gray-500 mt-1">
                              {deal.timeline.days_passed}/{deal.timeline.total_days} days completed
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {formatPercentage(deal.metrics.pacing_percentage)}
                        </div>
                        <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                          <div 
                            className={`h-2.5 rounded-full ${getStatusColor(deal.status).bar}`}
                            style={{ width: `${Math.min(100, deal.metrics.pacing_percentage)}%` }}
                          ></div>
                        </div>
                        <div className="text-xs text-gray-500 mt-1">
                          Target: {formatNumber(deal.metrics.daily_impressions_required)} impressions/day
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {formatCurrency(deal.delivery.budget_delivered)} / {formatCurrency(deal.delivery.budget_goal)}
                        </div>
                        <div className="text-sm text-gray-500">
                          {formatPercentage(deal.metrics.budget_delivered_percentage)} delivered
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {formatNumber(deal.delivery.impressions_delivered)} / {formatNumber(deal.delivery.impressions_goal)}
                        </div>
                        <div className="text-sm text-gray-500">
                          {formatPercentage(deal.metrics.quantity_delivered_percentage)} delivered
                        </div>
                      </td>
                      {selectedMetrics.map((metric) => (
                        <td key={metric} className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {formatMetricValue(metric, deal.metrics[metric as keyof Metrics])}
                          </div>
                        </td>
                      ))}
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          getStatusColor(deal.status).bg} ${getStatusColor(deal.status).text
                        }`}>
                          {getStatusDisplay(deal.status)}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-sm leading-5  `}>{deal.trader}</span>
                      </td>
                    </tr>

                    {expandedDeals.has(deal.deal_id) && deal.insertion_orders.map((io) => (
                      <tr key={io.insertion_order_id} className="bg-white hover:bg-gray-50 transition-colors">
                        <td className="px-6 py-4 pl-16 border-l-2 border-blue-200">
                          <div className="max-w-[500px] break-words">
                            <div className="text-sm font-medium text-gray-900">{io.insertion_order_name}</div>
                            <div className="text-sm text-gray-500">ID: {io.insertion_order_id}</div>
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="max-w-[500px] break-words">
                            <div className="text-sm text-gray-900">{io.campaign_name}</div>
                            <div className="text-sm text-gray-500">Campaign ID: {io.campaign_id}</div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center gap-2">
                            {get_logo_url(io.platform) && (
                              <img 
                                src={get_logo_url(io.platform)} 
                                alt={`${io.platform} logo`} 
                                className="w-5 h-5 object-contain"
                              />
                            )}
                            <div className="text-sm text-gray-900">{io.platform}</div>
                          </div>
                        </td>
                        <td colSpan={5 + selectedMetrics.length} className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500 italic">Part of Deal ID: {deal.deal_id}</div>
                        </td>
                        <td colSpan={5} className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm ">{deal.trader}</div>
                        </td>  
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <NoActionsFound sectionName="Deal Pacing" />
        )}
      </div>
    </div>
  );
};

export default PacingMargin;
