import React, { useState, useEffect, useRef } from "react";
import { IoClose } from "react-icons/io5";

interface MultiSelectDropdownProps {
  label?: string | null;
  items: string[];
  selectedItems: string[];
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  disabled?: boolean;
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  label,
  items,
  selectedItems,
  setSelectedItems,
  disabled = false,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const dropdownRef = useRef<HTMLDivElement>(null);

  // Filter items based on search term
  const filteredItems = items.filter(
    (item) =>
      item &&
      typeof item === "string" &&
      item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle item selection/deselection
  const handleSelect = (item: string) => {
    if (!disabled) {
      setSelectedItems((prev) =>
        prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
      );
    }
  };

  // Select or deselect all filtered items (based on search term)
  const handleSelectAll = () => {
    if (!disabled) {
      if (filteredItems.length === selectedItems.length) {
        // Deselect all if all filtered items are selected
        setSelectedItems((prev) =>
          prev.filter((item) => !filteredItems.includes(item))
        );
      } else {
        // Select only filtered items
        setSelectedItems(filteredItems);
      }
    }
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    if (!disabled) {
      setDropdownOpen((prev) => !prev);
    }
  };

  // Close dropdown if user clicks outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="mt-2" ref={dropdownRef}>
      <label
        className={`text-xs font-medium ${disabled ? "text-gray-500" : ""}`}
      >
        {label}
      </label>
      <div className="relative mt-1">
        <div
          className={`border p-3 cursor-pointer flex items-center min-h-[40px] ${
            disabled ? "bg-gray-200 cursor-not-allowed" : "bg-white"
          }`}
          onClick={toggleDropdown}
        >
          {selectedItems.length > 0 ? (
            <div className="flex flex-wrap gap-2 max-h-[230px] overflow-y-auto">
              {selectedItems.map((item) => (
                <div
                  key={item}
                  className="bg-gray-200 text-sm px-2 py-1 flex items-center"
                >
                  {item}
                  <button
                    className="ml-1"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent dropdown from closing
                      if (!disabled) {
                        setSelectedItems((prev) =>
                          prev.filter((i) => i !== item)
                        );
                      }
                    }}
                  >
                    <IoClose size={12} />
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <span
              className={`text-sm ${
                disabled ? "text-gray-400" : "text-gray-500"
              }`}
            >
              Select {label?.toLowerCase()}...
            </span>
          )}
        </div>

        {dropdownOpen && (
          <div
            className={`absolute z-10 w-full bg-white border mt-1 shadow-md max-h-48 overflow-auto ${
              disabled ? "pointer-events-none" : ""
            }`}
          >
            <input
              type="text"
              placeholder="Search..."
              className={`p-2 border-b w-full text-sm focus:outline-none ${
                disabled ? "bg-gray-200 cursor-not-allowed" : ""
              }`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              disabled={disabled}
            />
            <div
              className={`flex items-center p-2 hover:bg-[#15762A1A] cursor-pointer ${
                filteredItems.length === selectedItems.length
                  ? "bg-[#15762A1A]"
                  : ""
              } ${disabled ? "cursor-not-allowed" : ""}`}
              onClick={handleSelectAll}
            >
              <input
                type="checkbox"
                className="mr-2"
                checked={filteredItems.length === selectedItems.length}
                readOnly
              />
              <span className="text-sm">Select All</span>
            </div>
            <div>
              {filteredItems.map((item) => (
                <div
                  key={item}
                  className={`flex items-center p-2 hover:bg-[#15762A1A] cursor-pointer ${
                    selectedItems.includes(item) ? "bg-[#15762A1A]" : ""
                  } ${disabled ? "cursor-not-allowed" : ""}`}
                  onClick={() => handleSelect(item)}
                >
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={selectedItems.includes(item)}
                    readOnly
                  />
                  <span className="text-sm">{item}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiSelectDropdown;
