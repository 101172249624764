import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import MultiSelectDropdown from "../MultiSelectDropdown";
import toast from "react-hot-toast";
import axiosInstance from "../../utils/axiosInstance";
import Loader from "../statusIndicator/loader";

interface ConnectToDBPopupProps {
  closePopup: () => void;
  partnersIds: string[];
}

const RefreshChecksPopup: React.FC<ConnectToDBPopupProps> = ({
  closePopup,
  partnersIds,
}) => {
  const [fetchedAdvertisersList, setFetchedAdvertisersList] = useState<any>([]);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState<string[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [advertisersLoading, setAdvertisersLoading] = useState<boolean>(false);

  const handleFalseAction = () => {
    closePopup();
  };

  const getAdvertisersList = async (partnersIds: string[]) => {
    setAdvertisersLoading(true);
    try {
      const advertisersPromises = partnersIds.map(async (id) => {
        const response = await axiosInstance.get(
          `/connector/dv-360/advertisers/${id}/`
        );

        if (response.status === 200) {
          return response.data.data.advertisers;
        } else {
          throw new Error("Unexpected response while fetching advertisers");
        }
      });

      const results = await Promise.all(advertisersPromises);
      const allAdvertisers = results.flat();
      setFetchedAdvertisersList(allAdvertisers);
    } catch (error: any) {
      toast.error(
        error.response?.data?.message ||
          "Error fetching advertisers, please try again."
      );
    } finally {
      setAdvertisersLoading(false);
    }
  };

  useEffect(() => {
    getAdvertisersList(partnersIds);
    const hasClickedBefore = localStorage.getItem("refreshButtonClicked");
    if (hasClickedBefore === "true") {
      setIsButtonDisabled(true);
    }
  }, []);

  function extractIds(advertiser: string[]): string {
    if (advertiser.length > 0) {
      return advertiser
        .map((ad) => {
          const match = ad.match(/\((\d+)\)/);
          return match ? match[1] : null;
        })
        .filter((id) => id !== null)
        .join(",");
    }
    return "";
  }

  const handleClickRefresh = async () => {
    if (selectedAdvertiser && selectedAdvertiser.length > 0) {
      setLoading(true);
      try {
        const response = await axiosInstance.post("/checks/on-demand-checks", {
          advertiser_ids: extractIds(selectedAdvertiser),
        });
        if (response.status === 200) {
          toast.success("Refreshing checks for selected advertisers");
          setIsButtonDisabled(true);
          localStorage.setItem("refreshButtonClicked", "true");
        } else {
          throw new Error("Server error, please try again after some time");
        }
      } catch (error: any) {
        toast.error(
          "Error while refreshing checks for selected Advertisers, please try again."
        );
      } finally {
        setLoading(false);
        closePopup();
      }
    } else {
      toast.error("Please select at least one advertiser");
      return null;
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className="bg-white shadow-lg min-w-[480px] max-w-[660px] border border-gray-300"
        style={{
          boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
        }}
      >
        <div className="pt-6 px-8 pb-6">
          {/* popup heading */}
          <div className="flex justify-between">
            <div className="flex gap-2">
              <p className="text-base leading-5 font-extrabold">
                Refresh Checks
              </p>
            </div>
            <button onClick={handleFalseAction} className="h-5 w-5 text-center">
              <IoClose size={20} />
            </button>
          </div>
          {/* body */}
          <div className="mt-3">
            <p className="text-sm">Select Advertisers</p>
            {advertisersLoading ? (
              <div className="flex items-center gap-1 text-xs mt-4">
                <Loader />
                <span>Loading all Advertisers</span>
              </div>
            ) : (
              <MultiSelectDropdown
                items={fetchedAdvertisersList.map(
                  (item: { name: string; id: string }) =>
                    `${item.name}(${item.id})`
                )}
                selectedItems={selectedAdvertiser}
                setSelectedItems={setSelectedAdvertiser}
              />
            )}
          </div>
          {/* action button */}
          <div className="flex justify-end items-center gap-2 mt-4">
            <button
              className={`p-2 border ${
                isButtonDisabled
                  ? "text-gray-400 border-gray-300"
                  : "text-[#286043] border-[#286043]"
              } text-sm leading-5 font-[600]`}
              onClick={handleClickRefresh}
              disabled={isButtonDisabled}
            >
              {loading ? <Loader /> : <span>Refresh</span>}
            </button>
          </div>
          {/* note section */}
          <div className="flex justify-start items-center mt-4 gap-3 bg-gray-200 px-6 py-3">
            <p className="px-3 py-[6px] bg-[#FF9E44] text-white text-xs leading-4">
              NOTE
            </p>
            <p className="text-xs leading-4">
              The advertisers can only be refreshed once per session.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefreshChecksPopup;
