export const EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ?? "https://api.beta.atomicads.ai/api";

export const standardTimeZones = [
  "UTC-12:00 - Baker Island Time (BIT)",
  "UTC-11:00 - Niue Time (NUT), Samoa Standard Time (ST)",
  "UTC-10:00 - Hawaii-Aleutian Standard Time (HAST), Cook Islands Standard Time (CKT)",
  "UTC-09:30 - Marquesas Islands Time (MART)",
  "UTC-09:00 - Alaska Standard Time (AKST)",
  "UTC-08:00 - Pacific Standard Time (PST), Clipperton Island Standard Time",
  "UTC-07:00 - Mountain Standard Time (MST)",
  "UTC-06:00 - Central Standard Time (CST)",
  "UTC-05:00 - Eastern Standard Time (EST)",
  "UTC-04:00 - Atlantic Standard Time (AST), Eastern Caribbean Time (ECT)",
  "UTC-03:30 - Newfoundland Standard Time (NST)",
  "UTC-03:00 - Argentina Time (ART), Brasília Time (BRT)",
  "UTC-02:00 - South Georgia and the South Sandwich Islands Time",
  "UTC-01:00 - Azores Standard Time (AZOT), Cape Verde Time (CVT)",
  "UTC±00:00 - Coordinated Universal Time (UTC), Greenwich Mean Time (GMT)",
  "UTC+01:00 - Central European Time (CET), West Africa Time (WAT)",
  "UTC+02:00 - Eastern European Time (EET), Central Africa Time (CAT)",
  "UTC+03:00 - Moscow Standard Time (MSK), East Africa Time (EAT)",
  "UTC+03:30 - Iran Standard Time (IRST)",
  "UTC+04:00 - Gulf Standard Time (GST), Samara Time (SAMT)",
  "UTC+04:30 - Afghanistan Time (AFT)",
  "UTC+05:00 - Pakistan Standard Time (PKT), Yekaterinburg Time (YEKT)",
  "UTC+05:30 - Indian Standard Time (IST), Sri Lanka Standard Time",
  "UTC+05:45 - Nepal Time (NPT)",
  "UTC+06:00 - Bangladesh Standard Time (BST), Omsk Time (OMST)",
  "UTC+06:30 - Cocos Islands Time (CCT), Myanmar Time (MMT)",
  "UTC+07:00 - Indochina Time (ICT), Novosibirsk Time (NOVT)",
  "UTC+08:00 - China Standard Time (CST), Singapore Standard Time (SGT)",
  "UTC+08:45 - Australian Central Western Standard Time (ACWST)",
  "UTC+09:00 - Japan Standard Time (JST), Korea Standard Time (KST)",
  "UTC+09:30 - Australian Central Standard Time (ACST)",
  "UTC+10:00 - Australian Eastern Standard Time (AEST), Vladivostok Time (VLAT)",
  "UTC+10:30 - Lord Howe Standard Time (LHST)",
  "UTC+11:00 - Magadan Time (MAGT), Solomon Islands Time (SBT)",
  "UTC+12:00 - Fiji Time (FJT), Kamchatka Time (PETT)",
  "UTC+12:45 - Chatham Islands Standard Time (CHAST)",
  "UTC+13:00 - Tonga Time (TOT), Phoenix Islands Time (PHOT)",
  "UTC+14:00 - Line Islands Time (LINT)",
];

export const IS_ADMIN = true;

export const specific_campaigns = [
  "Walmart - Winter Deals 2025",
  "Zara - Summer Clearance",
  "Anytime Fitness - Free Trial Campaign",
  "Nike - Marathon Season 2025",
  "Delta Airlines - Explore Europe",
  "TravelWise - Early Bird Deals",
  "Sony - Immersive Experience",
  "Playstation - Winter Launch",
  "CarMax - Trade-In Bonanza",
  "Aston Martin - Performance Cars 2025",
];

export const specific_insertion_order = [
  "Walmart - Display - Retargeting - JanMar25 - 15042",
  "Zara - Display - Prospecting - Summer Clearance - 14192",
  "Anytime Fitness - Display - Prospecting - Free Trial - 15150",
  "Nike - Display - Prospecting - Generic Audience - 15155",
  "Delta Airlines - Display - Retargeting - Retargeting Audience - 15167",
  "TravelWise - Display - Prospecting - First Time Buyers - 15189",
  "Sony - Display - Prospecting - Early Bird Deals - 15345",
  "Playstation - Display - Prospecting - Christmas - 15006",
  "Aston Martin - Display - Prospecting - Leisure - 15045",
  "CarMax - Display - Prospecting - Commuters - 15135",
];

export const specific_line_item = [
  "Walmart - Display - Retargeting - JanMar25 - 15042",
  "Zara - Display - Prospecting - Summer Clearance - 14192",
  "Anytime Fitness - Display - Prospecting - Free Trial - 15150",
  "Nike - Display - Prospecting - Generic Audience - 15155",
  "Delta Airlines - Display - Retargeting - Retargeting Audience - 15167",
  "TravelWise - Display - Prospecting - First Time Buyers - 15189",
  "Sony - Display - Prospecting - Early Bird Deals - 15345",
  "Playstation - Display - Prospecting - Christmas - 15006",
  "Aston Martin - Display - Prospecting - Leisure - 15045",
  "CarMax - Display - Prospecting - Commuters - 15135",
];

export const specific_creative = [
  "Walmart - Banner - Winter Blowout Sale",
  "Zara - Static Ad - Up to 70% Off",
  "Anytime Fitness - Video Ad - Your Fitness Journey Starts Here",
  "Nike - Video Ad - Run Stronger, Run Faster",
  "Delta Airlines - Banner - Book Now, Travel Later",
  "TravelWise - Static Ad - Fly Smarter, Save More",
  "Sony - Static Ad - Immersive Worlds Await",
  "Aston Martin - Banner - Drive the Future Today",
  "CarMax - Static Ad - Trade Up, Drive Better",
  "CarMax - Video Ad - The Best Deals for Your Next Ride",
];

export const specific_youtube_ad_group = [
  "Walmart - Winter Shoppers - TrueView",
  "Zara - Summer Fashion Lovers - Skippable Ads",
  "Anytime Fitness - Active Lifestyle Audience - TrueView",
  "Delta Airlines - Adventure Seekers - Discovery Ads",
  "Playstation - Gamers and Streamers - Bumper Ads",
];

export const condition_metrics = [
  "Impressions",
  "Clicks",
  "CPM",
  "CPC",
  "CTR",
  "Ad Spend",
  "Conversions",
  "CPA",
  "Margin",
  "Pacing",
  "Completed Views",
  "VTR",
  "CPV",
  "TrueView:Views",
  "Viewability",
];

export const level_metrics = [
  "All Active Campaigns",
  "Specific Campaign",
  "All Active Insertion Order",
  "Specific Insertion Order",
  "All Active Line Items",
  "Specific Line Item",
  "All Creatives",
  "Specific Creative",
  "All Youtube Ad Group",
  "Specific Youtube Ad Group",
];

export const time_range = [
  "Yesterday",
  "Last 7 days",
  "Last 14 days",
  "Last 30 days",
  "All Time",
];
