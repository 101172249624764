import React, { useState, useEffect } from "react";
import SingleSelectDropdown from "../SingleSelectDropdown";

interface ViewabilityPopupProps {
  setFormValue: React.Dispatch<
    React.SetStateAction<{
      [key: string]: { dropdown?: string; input?: string };
    }>
  >;
}

const ViewabilityPopup: React.FC<ViewabilityPopupProps> = ({
  setFormValue,
}) => {
  const [isBrokenByCampType, setIsBrokenByCampType] = useState<string | null>(
    "Yes"
  );
  const [selectedCampProspecting, setSelectedCampProspecting] = useState<
    string | null
  >("Prospecting");
  const [selectedCampProspectingValues, setSelectedCampProspectingValues] =
    useState<string | null>(null);

  const [selectedCampRetargeting, setSelectedCampRetargeting] = useState<
    string | null
  >("Retargeting");
  const [selectedCampRetargetingValues, setSelectedCampRetargetingValues] =
    useState<string | null>(null);

  const [selectedViewabilityStandards, setSelectedViewabilityStandards] =
    useState<string | null>(null);

  const options = [
    "10% or greater",
    "20% or greater",
    "30% or greater",
    "40% or greater",
    "50% or greater",
    "60% or greater",
    "70% or greater",
    "80% or greater",
    "90% or greater",
    "All Impressions",
  ];

  // Update the formValue whenever a change is made in any of the fields
  useEffect(() => {
    setFormValue((prevState) => ({
      ...prevState,
      "Is Broken By Campaign Type": { dropdown: isBrokenByCampType ?? "" },
      "Campaign Type Prospecting": { dropdown: selectedCampProspecting ?? "" },
      "Prospecting Campaigns Type List": {
        dropdown: selectedCampProspectingValues ?? "",
      },
      "Campaign Type Retargeting": { dropdown: selectedCampRetargeting ?? "" },
      "Retargeting Campaigns Type List": {
        dropdown: selectedCampRetargetingValues ?? "",
      },
      "Campaigns List": { dropdown: selectedViewabilityStandards ?? "" },
    }));
  }, [
    isBrokenByCampType,
    selectedCampProspecting,
    selectedCampProspectingValues,
    selectedCampRetargeting,
    selectedCampRetargetingValues,
    selectedViewabilityStandards,
    setFormValue,
  ]);

  return (
    <>
      <div className="my-3">
        <p className="text-sm">
          Do you want the channel list to be broken down by campaign type?
        </p>
        <div className="w-full">
          <SingleSelectDropdown
            items={["Yes", "No"]}
            selectedItem={isBrokenByCampType}
            setSelectedItem={(value) => setIsBrokenByCampType(value)}
          />
        </div>
      </div>
      {isBrokenByCampType === "Yes" ? (
        <div className="flex gap-8">
          <div>
            <div className="my-3">
              <p className="text-sm">Select Campaign Type</p>
              <div className="w-full">
                <SingleSelectDropdown
                  items={["Prospecting"]}
                  selectedItem={selectedCampProspecting}
                  setSelectedItem={(value) => setSelectedCampProspecting(value)}
                  disabled
                />
              </div>
            </div>
            <div className="my-3">
              <p className="text-sm">
                Select Viewability Standard For Prospecting
              </p>
              <div className="w-full">
                <SingleSelectDropdown
                  items={options}
                  selectedItem={selectedCampProspectingValues}
                  setSelectedItem={(value) =>
                    setSelectedCampProspectingValues(value)
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <div className="my-3">
              <p className="text-sm">Select Campaign Type</p>
              <div className="w-full">
                <SingleSelectDropdown
                  items={["Retargeting"]}
                  selectedItem={selectedCampRetargeting}
                  setSelectedItem={(value) => setSelectedCampRetargeting(value)}
                  disabled
                />
              </div>
            </div>
            <div className="my-3">
              <p className="text-sm">
                Select Viewability Standard for Retargeting
              </p>
              <div className="w-full">
                <SingleSelectDropdown
                  items={options}
                  selectedItem={selectedCampRetargetingValues}
                  setSelectedItem={(value) =>
                    setSelectedCampRetargetingValues(value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="my-3">
          <p className="text-sm">Select Viewability Standards</p>
          <div className="w-full">
            <SingleSelectDropdown
              items={options}
              selectedItem={selectedViewabilityStandards}
              setSelectedItem={(value) =>
                setSelectedViewabilityStandards(value)
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ViewabilityPopup;
