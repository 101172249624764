import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import UserAvatar from "../components/userAvatar/UserAvatar";
import { MdLibraryBooks } from "react-icons/md";
import RulesTable from "../components/rulesLibrary/RulesTable";
import allRulesData from "../json/tables/ruleLibrary/allRuleLibraryData.json";
import rulesPopupData from "../json/tables/ruleLibrary/allRulesPopup.json";
import youtubePopupData from "../json/tables/ruleLibrary/youtubePopup.json";
import { FetchedRule, Rule, RulesWithPopup } from "../interfaces/rules";
import { useProfile } from "../context/ProfileContext";
import toast from "react-hot-toast";
import axiosInstance from "../utils/axiosInstance";
import RuleLibraryActionPopup from "../components/rulesLibrary/RuleLibraryActionPopup";
import dataSwitch from "../json/switch.json";
import SkeletonTable from "../components/Skeleton";
import RequestNewRulePopup from "../components/rulesLibrary/RequestNewRulePopup";
import { useNavigate } from "react-router-dom";
import { get_logo_url } from "../utils/getConnectorsLogo";
import Loader from "../components/statusIndicator/loader";

const RuleLibrary: React.FC = () => {
  const { isAdmin } = useProfile();
  const navigate = useNavigate();
  const [liveRulesTableData, setLiveRulesTableData] = useState<Rule[]>();
  const { accessToken, refreshToken, refreshAccessToken, fullName } = useAuth();

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [showActionPopup, setShowActionPopup] = useState<boolean>(false);
  const [selectedActionPopupData, setSelectedActionPopupData] = useState<Rule>(
    {} as Rule
  );

  const [tableDataLoading, setTableDataLoading] = useState<boolean>(false);
  const [isApplyChecksClicked, setIsApplyChecksClicked] =
    useState<boolean>(false);

  const [showRequestNewRulePopup, setShowRequestNewRulePopup] =
    useState<boolean>(false);

  const [selectDspType, setSelectDspType] = useState<string>("DV360");

  const [selectDspChannel, setSelectDspChannel] = useState<string>("Display");
  const [isApplyChecksLoading, setIsApplyChecksLoading] =
    useState<boolean>(false);

  // Use a ref to track the previous length of selectedRows
  const prevSelectedRowsLength = useRef<number>(0);

  const template_data = ["DV360", "TTD"];

  const dv360_channels = ["Display", "Youtube"];

  const getAllDsps = () => {
    const allDsps: { dsp_name: string; logo: string }[] = template_data.map(
      (dsp) => ({
        dsp_name: dsp,
        logo: get_logo_url(dsp),
      })
    );
    return allDsps;
  };

  const getActiveDspData = () => {
    const activeDspRule = template_data.filter((dsp) => dsp === selectDspType);
    return activeDspRule.length > 0 ? activeDspRule[0] : null;
  };

  const activeDsp = getActiveDspData();

  const handleTableDataFormation = (
    allRulesData: FetchedRule[],
    rulesPopupData: RulesWithPopup[]
  ) => {
    const mergedJson = allRulesData.map((item1) => {
      const item2 = rulesPopupData.find(
        (item2) => item2.check_name === item1.check_name
      );
      if (item2) {
        return {
          ...item1,
          ...item2,
        };
      }
      return item1;
    });

    return mergedJson;
  };

  // have to pass all fetched data to update the json
  const updateJson = (data: any) => {
    Object.keys(data).forEach((dspKey) => {
      const channels = data[dspKey];

      // If channels is an array, iterate over it directly
      if (Array.isArray(channels)) {
        channels.forEach((item) => {
          if (typeof item === "object" && item !== null) {
            item.dsp_name = dspKey;
            item.channel_name = null; // Default to null if no channel is specified
          } else {
            console.warn(`Skipping non-object item: ${item}`);
          }
        });
      } else if (typeof channels === "object" && channels !== null) {
        // If channels is an object (e.g. with keys for different channel types)
        Object.keys(channels).forEach((channelKey) => {
          const channelData = channels[channelKey];

          // If channelData is an array, iterate over it
          if (Array.isArray(channelData)) {
            channelData.forEach((item: any) => {
              if (typeof item === "object" && item !== null) {
                item.dsp_name = dspKey;
                item.channel_name = channelKey;
              } else {
                console.warn(`Skipping non-object item in array: ${item}`);
              }
            });
          } else if (typeof channelData === "object" && channelData !== null) {
            // If channelData is a single object, handle it as such
            channelData.dsp_name = dspKey;
            channelData.channel_name = channelKey;
          }
        });
      }
    });
    return data;
  };

  function sortByCategory(arr: any[]) {
    return arr.sort((a: any, b: any) => a.category.localeCompare(b.category));
  }

  const fetchRulesTableData = async () => {
    setTableDataLoading(true);
    if (dataSwitch.use_demo_data_for_rules_library) {
      setTimeout(() => {
        if (activeDsp === "DV360") {
          if (selectDspChannel === "Display") {
            const updatedJson = updateJson(allRulesData);
            const mergedJson = handleTableDataFormation(
              updatedJson.DV360.Display,
              rulesPopupData
            );
            setLiveRulesTableData(sortByCategory(mergedJson) as Rule[]);
          } else if (selectDspChannel === "Youtube") {
            const updatedJson = updateJson(allRulesData);
            const mergedJson = handleTableDataFormation(
              updatedJson.DV360.Youtube,
              youtubePopupData
            );
            setLiveRulesTableData(
              sortByCategory(updateJson(mergedJson)) as Rule[]
            );
          }
        } else if (activeDsp === "TTD") {
          setLiveRulesTableData(updateJson(allRulesData.TTD) as Rule[]);
        }
        setTableDataLoading(false);
      }, 2000);
    } else {
      try {
        if (activeDsp === "DV360") {
          const response = await axiosInstance.get(
            "/checks/pre-defined-checks"
          );

          const updatedJson = updateJson(response.data.data.default_checks);
          if (selectDspChannel === "Display") {
            const mergedJson = handleTableDataFormation(
              updatedJson.DV360.Display,
              rulesPopupData
            );
            setLiveRulesTableData(sortByCategory(mergedJson) as Rule[]);
          } else if (selectDspChannel === "Youtube") {
            const mergedJson = handleTableDataFormation(
              updatedJson.DV360.Youtube,
              youtubePopupData
            );
            setLiveRulesTableData(
              sortByCategory(updateJson(mergedJson)) as Rule[]
            );
          }
        } else if (activeDsp === "TTD") {
          setLiveRulesTableData(updateJson(allRulesData.TTD) as Rule[]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setTableDataLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    } else {
      fetchRulesTableData();
    }
  }, [
    accessToken,
    refreshToken,
    refreshAccessToken,
    activeDsp,
    selectDspChannel,
  ]);

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    }
  }, [accessToken, refreshToken, refreshAccessToken]);

  // Handle individual row selection
  const handleRowSelect = (item: {
    check_name: string;
    id: string;
    dsp_name: string;
    channel_name: string | null;
    category: string;
  }) => {
    // Check if the item already exists in selectedRows by matching all three properties
    const isAlreadySelected = selectedRows.some(
      (r) =>
        r.check_name === item.check_name &&
        r.dsp_name === item.dsp_name &&
        r.channel_name === item.channel_name
    );

    if (isAlreadySelected) {
      // If already selected, remove the item from selectedRows
      setSelectedRows(
        selectedRows.filter(
          (r) =>
            !(
              r.check_name === item.check_name &&
              r.dsp_name === item.dsp_name &&
              r.channel_name === item.channel_name
            )
        )
      );
    } else {
      // If not selected, add the item to selectedRows
      setSelectedRows([
        ...selectedRows,
        {
          check_name: item.check_name,
          id: item.id,
          dsp_name: item.dsp_name,
          channel_name: item.channel_name,
          category: item.category,
        },
      ]);
    }
  };

  // Handle "select all" checkbox
  const handleSelectAll = () => {
    if (liveRulesTableData) {
      if (selectedRows.length === liveRulesTableData.length) {
        setSelectedRows([]);
      } else {
        const allCheckNames = liveRulesTableData.map((rule) => ({
          check_name: rule.check_name,
          id: rule.id,
          dsp_name: rule.dsp_name,
          channel_name: rule.channel_name,
        }));
        setSelectedRows(allCheckNames);
      }
    }
  };

  // Handle action popup
  const handleSaveOnPopup = (popupData: any) => {
    const exists = selectedRows.some(
      (row) =>
        row.check_name === popupData.check_name &&
        row.dsp_name === popupData.dsp_name &&
        row.channel_name === popupData.channel_name
    );

    if (!exists) {
      setSelectedRows([...selectedRows, popupData]);
    } else {
      const updatedRows = selectedRows.map((row) =>
        row.check_name === popupData.check_name &&
        row.dsp_name === popupData.dsp_name &&
        row.channel_name === popupData.channel_name
          ? { ...row, ...popupData }
          : row
      );
      setSelectedRows(updatedRows);
    }
    toast.success("Customization added successfully");
  };

  const handleApplyChecks = async () => {
    setIsApplyChecksLoading(true);

    const response = await axiosInstance.post(
      "/checks/apply-organisation-checks",
      {
        data: selectedRows,
      }
    );

    if (response.status === 200) {
      toast.success(
        "Checks Applied, We are syncing with your DV360 account. Please check back in 2 hours to view opportunities."
      );
      setSelectedRows([]);
      setIsApplyChecksClicked(true);
      navigate("/opportunities");
    } else {
      toast.error(response.data.message);
    }
  };

  const handleOnCloseActionPopup = () => {
    setShowActionPopup(false);
    setSelectedActionPopupData({} as Rule);
  };

  useEffect(() => {
    // Only trigger this effect when "Apply Checks" is not clicked
    if (
      !isApplyChecksClicked &&
      selectedRows.length < prevSelectedRowsLength.current
    ) {
      toast.error("Customization is removed");
    }
    prevSelectedRowsLength.current = selectedRows.length;
    setIsApplyChecksClicked(false); // Reset flag after the effect runs
  }, [selectedRows, isApplyChecksClicked]);

  const handleRequestNewRule = () => {
    setShowRequestNewRulePopup(false);
  };

  const handleRequestNewRuleSubmitBtn = (item: {
    check_title: string;
    selected_levels: string[];
    data_source: string;
  }) => {
    console.log(item);
  };

  useEffect(() => {
    setSelectedRows([]);
  }, [selectDspType, selectDspChannel]);

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="rule library" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        {fullName && <UserAvatar />}
        <div className="flex justify-start items-center gap-4 mt-4 border-b-[0.75px] pb-[21px]">
          <div
            className="w-11 h-11 border border-solid flex justify-center items-center bg-[#E4FAFF]"
            style={{
              borderWidth: "0.5px",
              borderImageSource:
                "linear-gradient(141.57deg, #47F1FC 7.54%, rgba(93, 220, 248, 0) 102.53%)",
              borderImageSlice: 1,
            }}
          >
            <MdLibraryBooks size={20} />
          </div>
          <div>
            <p className="text-xl font-[700] leading-6 text-left">
              Rule Library
            </p>
            <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
              Keep your rules on track
            </p>
          </div>
        </div>
        <div className="border-l-0 border-r-0 border-b-[0.3px] mt-2 py-4 flex justify-end items-center">
          <div className="flex gap-2 items-center">
            <button
              className="border-[0.75px] border-[#286043] bg-[#286043] text-white text-sm font-semibold py-[6px] px-3 h-12 flex items-center"
              onClick={() => setShowRequestNewRulePopup(true)}
            >
              Request New Rule
            </button>
          </div>
        </div>

        {/* Select DSP */}
        <div className="flex gap-2 mt-6 border-l-0 border-r-0 border-b-[0.3px] mb-3 pb-6">
          {getAllDsps().map((item) => (
            <button
              key={item.dsp_name}
              className={`border p-2 text-sm ${
                selectDspType === item.dsp_name ? "border-[#286043]" : ""
              }`}
              onClick={() => setSelectDspType(item.dsp_name)}
            >
              <span className="flex gap-2">
                <img
                  src={item.logo}
                  alt={`${item.dsp_name} logo`}
                  className="h-5 w-5"
                />
                {item.dsp_name}
              </span>
            </button>
          ))}
        </div>

        {/* Select DSP channels */}
        {selectDspType === "DV360" && (
          <>
            <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
              Select Channel
            </p>
            <div className="flex gap-2 mt-6 border-l-0 border-r-0 border-b-[0.3px] mb-3 pb-6">
              {dv360_channels.map((item) => (
                <button
                  key={item}
                  className={`border p-2 text-sm ${
                    selectDspChannel === item ? "border-[#286043]" : ""
                  }`}
                  onClick={() => setSelectDspChannel(item)}
                >
                  <span className="flex gap-2">{item}</span>
                </button>
              ))}
            </div>
          </>
        )}

        <div>
          <div className="flex justify-between items-center">
            <p className="text-gray-900 text-base leading-5 text-left font-bold py-4">
              Pre Defined Checks
              {liveRulesTableData && liveRulesTableData.length > 0
                ? `(${liveRulesTableData.length})`
                : ""}
            </p>
          </div>

          <div className="border-[0.75px] border-gray-300 py-6 px-4">
            {tableDataLoading ? (
              <SkeletonTable />
            ) : (
              liveRulesTableData && (
                <RulesTable
                  tableData={liveRulesTableData}
                  selectedRows={selectedRows}
                  handleRowSelect={handleRowSelect}
                  handleSelectAll={handleSelectAll}
                  setShowActionPopup={setShowActionPopup}
                  setPopupData={setSelectedActionPopupData}
                  activeDSP={activeDsp}
                  activeDSPChannel={selectDspChannel}
                />
              )
            )}
            <div className="flex justify-end">
              <button
                className={`border-[0.75px] border-[#286043] ${
                  selectedRows.length > 0 ? "bg-[#286043]" : "bg-gray-200"
                } ${
                  selectedRows.length > 0 ? "text-white" : "text-gray-500"
                } text-sm font-semibold py-[6px] px-3 h-12 flex items-center mt-4`}
                onClick={handleApplyChecks}
              >
                {isApplyChecksLoading ? (
                  <div className="flex items-center gap-2">
                    <Loader loaderColor="white" />
                    <p>Applying Checks</p>
                  </div>
                ) : (
                  "Apply Checks"
                )}
              </button>
            </div>
          </div>
        </div>

        {showActionPopup && (
          <RuleLibraryActionPopup
            onClose={handleOnCloseActionPopup}
            popupData={selectedActionPopupData}
            handleSaveOnPopup={handleSaveOnPopup}
          />
        )}

        {showRequestNewRulePopup && (
          <RequestNewRulePopup
            closePopup={handleRequestNewRule}
            handleRequestNewRuleSubmitBtn={handleRequestNewRuleSubmitBtn}
          />
        )}
      </div>
    </div>
  );
};

export default RuleLibrary;
