// Extracts IDs enclosed in parentheses from an array of strings and returns them as a string array.
export const getMatchingIds = (value: string[]): string[] => {
  const regex = /\(([^)]+)\)/;
  return value
    .map((item) => {
      const match = item.match(regex);
      return match ? match[1] : null;
    })
    .filter((id): id is string => id !== null);
};
