import React from "react";
import { FaEdit } from "react-icons/fa";
import { Rule, RulesTableProps } from "../../interfaces/rules";
import { TbSquareRoundedCheckFilled } from "react-icons/tb";

const RulesTable: React.FC<RulesTableProps> = ({
  tableData,
  selectedRows,
  handleRowSelect,
  handleSelectAll,
  setShowActionPopup,
  setPopupData,
  activeDSP,
  activeDSPChannel,
}) => {
  const handleActionButtonClick = (rule: Rule) => {
    setPopupData(rule);
    setShowActionPopup(true);
  };

  return (
    <div>
      {/* Table Header with Select All Checkbox */}
      <div
        className={`text-gray-900 text-xs font-bold border-[0.5px] p-3 border-gray-300 bg-gray-100 grid ${
          activeDSP === "DV360"
            ? activeDSPChannel === "Display"
              ? "grid-cols-8"
              : "grid-cols-9"
            : "grid-cols-6"
        }`}
      >
        <p className="col-span-2 flex justify-start items-center mr-4">
          <input
            type="checkbox"
            checked={selectedRows.length === tableData.length}
            onChange={handleSelectAll}
            className="mr-3"
          />
          <span>CHECK NAME</span>
        </p>
        {activeDSP === "DV360" && <p className="col-span-1">CATEGORY</p>}
        <p className="col-span-1">CAMPAIGN</p>
        {activeDSP === "DV360" ? (
          activeDSPChannel === "Display" ? (
            <>
              <p className="col-span-1">INSERTION ORDER</p>
              <p className="col-span-1">LINE ITEM</p>
              <p className="col-span-1">CREATIVE</p>
            </>
          ) : (
            <>
              <p className="col-span-1">INSERTION ORDER</p>
              <p className="col-span-1">LINE ITEM</p>
              <p className="col-span-1">AD GROUP</p>
              <p className="col-span-1">AD</p>
            </>
          )
        ) : (
          <>
            <p className="col-span-1">AD GROUP</p>
            <p className="col-span-1">CREATIVE</p>
          </>
        )}
        <p className="col-span-1">ACTION</p>
      </div>

      {/* Table Rows with Individual Checkboxes */}
      {tableData.map((rule, index) => (
        <ul
          key={index}
          className={`text-gray-900 text-xs text-left border-b-[0.5px] p-3 border-gray-300 grid ${
            activeDSP === "DV360"
              ? activeDSPChannel === "Display"
                ? "grid-cols-8"
                : "grid-cols-9"
              : "grid-cols-6"
          }`}
        >
          {/* Check name */}
          <p className="col-span-2 whitespace-wrap flex justify-start items-center mr-4">
            <input
              type="checkbox"
              checked={selectedRows.some(
                (row) =>
                  row.check_name === rule.check_name &&
                  row.dsp_name === rule.dsp_name &&
                  row.channel_name === rule.channel_name
              )}
              onChange={() =>
                handleRowSelect({
                  check_name: rule.check_name,
                  id: rule.id,
                  dsp_name: rule.dsp_name || "",
                  channel_name: rule.channel_name || null,
                  category: rule?.category || "",
                })
              }
              className="mr-3 cursor-pointer"
            />
            <span>{rule.check_name}</span>
          </p>

          {/* Category */}
          {activeDSP === "DV360" && (
            <p className="col-span-1 whitespace-wrap">{rule?.category}</p>
          )}

          {/* Campaign */}
          <p className="col-span-1 whitespace-wrap">
            {rule.campaign ? (
              <TbSquareRoundedCheckFilled size={20} color="darkgreen" />
            ) : (
              "-"
            )}
          </p>
          {activeDSP === "DV360" ? (
            activeDSPChannel === "Display" ? (
              <>
                <p className="col-span-1 whitespace-wrap">
                  {rule.insertion_order ? (
                    <TbSquareRoundedCheckFilled size={20} color="darkgreen" />
                  ) : (
                    "-"
                  )}
                </p>
                <p className="col-span-1 whitespace-wrap">
                  {rule.line_item ? (
                    <TbSquareRoundedCheckFilled size={20} color="darkgreen" />
                  ) : (
                    "-"
                  )}
                </p>
                <p className="col-span-1 whitespace-wrap">
                  {rule.creative ? (
                    <TbSquareRoundedCheckFilled size={20} color="darkgreen" />
                  ) : (
                    "-"
                  )}
                </p>
              </>
            ) : (
              <>
                <p className="col-span-1 whitespace-wrap">
                  {rule.insertion_order ? (
                    <TbSquareRoundedCheckFilled size={20} color="darkgreen" />
                  ) : (
                    "-"
                  )}
                </p>
                <p className="col-span-1 whitespace-wrap">
                  {rule.line_item ? (
                    <TbSquareRoundedCheckFilled size={20} color="darkgreen" />
                  ) : (
                    "-"
                  )}
                </p>
                <p className="col-span-1 whitespace-wrap">
                  {rule.ad_group ? (
                    <TbSquareRoundedCheckFilled size={20} color="darkgreen" />
                  ) : (
                    "-"
                  )}
                </p>
                <p className="col-span-1 whitespace-wrap">
                  {rule.ad ? (
                    <TbSquareRoundedCheckFilled size={20} color="darkgreen" />
                  ) : (
                    "-"
                  )}
                </p>
              </>
            )
          ) : (
            <>
              <p className="col-span-1 whitespace-wrap">
                {rule.ad_group ? (
                  <TbSquareRoundedCheckFilled size={20} color="darkgreen" />
                ) : (
                  "-"
                )}
              </p>
              <p className="col-span-1 whitespace-wrap">
                {rule.creative ? (
                  <TbSquareRoundedCheckFilled size={20} color="darkgreen" />
                ) : (
                  "-"
                )}
              </p>
            </>
          )}

          {/* action button */}
          <div className="col-span-1 whitespace-wrap flex gap-3">
            <button
              onClick={() => handleActionButtonClick(rule)}
              disabled={rule.popup_heading !== undefined ? false : true}
            >
              <FaEdit
                color={rule.popup_heading !== undefined ? "#286043" : "gray"}
                size={16}
              />
            </button>
          </div>
        </ul>
      ))}
    </div>
  );
};

export default RulesTable;
