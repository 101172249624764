import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import campaign_page_log from "../assets/campaign_page_logo.png";
import UserAvatar from '../components/userAvatar/UserAvatar';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../utils/axiosInstance';
import toast from 'react-hot-toast';


// Define the type for an on-demand check
interface OnDemandCheck {
  id: number;
  status: 'In Progress' | 'Finished' | 'Failed';
  run_started_at: string;
  advertisers: string;
  run_completed_at: string | null;
  checks_created: number;
  user: string;
}


// Utility function to format timestamps
const formatTimestamp = (timestamp: string | null): string => {
  if (!timestamp) return '-';
  try {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });
  } catch (error) {
    return '-';
  }
};

const OnDemandCheck: React.FC = () => {
  const [onDemandChecks, setOnDemandChecks] = useState<OnDemandCheck[]>([]);
  const { accessToken, refreshToken, refreshAccessToken, fullName } = useAuth();
  const [loadingOnDemandChecks, setLoadingOnDemandChecks] = useState<boolean>(
    false
  );

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    }
  }, [accessToken, refreshToken, refreshAccessToken]);

  const fetchOnDemandChecks = async () => {
    const response = await axiosInstance.get(
      '/checks/on-demand-checks'
    );

    if (response.status === 200) {
      setOnDemandChecks(response.data.data);
    } else {
      toast.error('Error fetching on-demand checks, please try again.');
    }
  };

  useEffect(() => {
    setLoadingOnDemandChecks(true);
    try {
      fetchOnDemandChecks();
    } catch (error) {
      toast.error('Error fetching on-demand checks, please try again.');
    } finally {
      setLoadingOnDemandChecks(false);
    }
  }, [accessToken]);
    
  if (loadingOnDemandChecks) {
    return (
      <div className="flex justify-center items-center">
        <div className="w-full h-full flex justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      </div>
    );
  }


  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="on-demand-check" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        <UserAvatar />
        <div className="flex justify-between items-end mt-4">
          <div className="flex justify-start items-center gap-4 mt-4">
            <div
              className="w-11 h-11 border border-solid flex justify-center items-center bg-[#FFF8E4]"
              style={{
                borderWidth: '0.5px',
                borderImageSource:
                  'linear-gradient(141.57deg, #FCD447 7.54%, rgba(93, 220, 248, 0) 102.53%)',
                borderImageSlice: 1,
              }}
            >
              <img
                src={campaign_page_log}
                alt="opportunities logo"
                className="w-[17.5px] h-[17.5px]"
              />
            </div>
            <div>
              <p className="text-xl font-[700] leading-6 text-left">
                On-Demand Checks
              </p>
              <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
                Monitor the status of your on-demand quality assurance checks
              </p>
            </div>
          </div>
        </div>

        {/* Table for On-Demand Checks */}
        <div className="mt-6">
          <div className="overflow-x-auto">
            <table className="min-w-full border border-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b">
                    Status
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b">
                    Run Time
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b">
                    Advertiser
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b">
                    Completion Time
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b">
                    New Checks Created
                  </th>
                  <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b">
                    User
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {onDemandChecks.length > 0 ? (
                  onDemandChecks.map((check) => (
                    <tr key={check.id}>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <span
                          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            check.status === 'In Progress'
                              ? 'bg-blue-100 text-blue-800'
                              : check.status === 'Finished'
                              ? 'bg-green-100 text-green-800'
                              : 'bg-red-100 text-red-800'
                          }`}
                        >
                          {check.status}
                        </span>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                        {formatTimestamp(check.run_started_at)}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                        {check.advertisers}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                        {formatTimestamp(check.run_completed_at)}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                        {check.checks_created ? check.checks_created : 0}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900">
                        {check.user}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={6}
                      className="px-4 py-3 text-center text-sm text-gray-500"
                    >
                      No on-demand checks found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnDemandCheck;