import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import SingleSelectDropdown from "./SingleSelectDropdown";
import { standardTimeZones } from "../constants/index";
import axiosInstance from "../utils/axiosInstance";
import toast from "react-hot-toast";
import Loader from "./statusIndicator/loader";
import GreenTick from "./statusIndicator/greenTick";
import Google_logo from "../assets/google_logo.svg";
import { useGoogleLogin, CodeResponse } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
interface ConnectToDBPopupProps {
  closePopup: () => void;
}

interface PartnerProps {
  name: string;
  id: string;
}

const ConnectToDBPopup: React.FC<ConnectToDBPopupProps> = ({ closePopup }) => {
  const [selectedPartnerAccount, setSelectedPartnerAccount] = useState<
    string | null
  >("");
  const [selectedTimeZone, setSelectedTimeZone] = useState<string | null>("");
  const [partnerList, setPartnerList] = useState<PartnerProps[]>([]);
  const [authorizedDspAccount, setAuthorizedDspAccount] = useState<
    string | null
  >(null);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [authLoading, setAuthLoading] = useState<boolean>(false);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [scope, setScope] = useState<string>("");

  const handleFalseAction = () => {
    closePopup();
  };

  const getPartnersData = async () => {
    try {
      const response = await axiosInstance.get(`/connector/dv-360/partners/`);
      if (response.status === 200) {
        setPartnerList(response.data.data.partners);
      } else {
        throw new Error("Unexpected response while fetching partners");
      }
    } catch (error: any) {
      toast.error(
        error.response?.data?.message ||
          "Error fetching partners, please try again."
      );
    }
  };

  const getScope = async () => {
    try {
      const response = await axiosInstance.get(
        `/connector/google-dv360-oauth/`
      );

      if (response.status === 200) {
        setScope(response.data.data.scope.join(" "));
      }
    } catch (error) {
      toast.error("Error fetching scope, please try again");
    }
  };

  useEffect(() => {
    getScope();
  }, []);

  const createDSPAccount = async (tokenCode: string) => {
    try {
      setAuthLoading(true); // Ensure loading state is set before request

      const response = await axiosInstance.post(
        `/connector/google-dv360-oauth/`,
        {
          code: tokenCode,
          configuration_name: `Google DV360-${tokenCode.slice(0, 7)}`,
        }
      );

      if (response.status === 200 && response.data?.data?.external_id) {
        setAuthorizedDspAccount(response.data.data.external_id);
        setIsAuthorized(true);
      } else {
        toast.error("Unexpected response while fetching partners");
        setIsAuthorized(false);
      }
    } catch (error) {
      toast.error("Error fetching scope, please try again");
      setIsAuthorized(false);
    } finally {
      setAuthLoading(false);
    }
  };

  const handleSaveBtn = async () => {
    setIsSaveLoading(true);
    const getPartnerIdByName = (name: string | null): string | null => {
      const partner = partnerList.find((partner) => partner.name === name);
      return partner ? partner.id : null;
    };

    const extractUTCOffset = (timezoneString: string): string | null => {
      const pattern = /UTC([+-]\d{1,2}:\d{2})/;
      const match = timezoneString.match(pattern);
      return match ? match[1] : null;
    };
    const response = await axiosInstance.post(`/onboarding/step-1`, {
      partner_id: getPartnerIdByName(selectedPartnerAccount),
      timezone: `UTC${extractUTCOffset(selectedTimeZone ?? "")}`,
      dsp_account_external_id: authorizedDspAccount,
    });

    if (response.status === 200) {
      toast.success(
        "Account created successfully. Navigating to Rule Library Page."
      );
      setIsSaveLoading(false);
      closePopup();

      setTimeout(() => {
        navigate("/rule-library");
      }, 2000);
    } else {
      setIsSaveLoading(false);
      toast.error("Error creating account, please try again");
    }
  };

  const login = useGoogleLogin({
    onSuccess: (
      tokenResponse: Omit<
        CodeResponse,
        "error" | "error_description" | "error_uri"
      >
    ) => {
      console.log(tokenResponse.code);
      createDSPAccount(tokenResponse.code).then(() => {
        getPartnersData();
      });
      toast.success("Authorization successful!");
    },
    onError: (
      error: Pick<CodeResponse, "error" | "error_description" | "error_uri">
    ) => {
      console.error("Google login failed", error);
      setAuthLoading(false);
      toast.error("Authorization failed. Please try again.");
    },
    flow: "auth-code",
    scope: scope,
    prompt: "consent",
    access_type: "offline",
  } as any);

  const handleClickAuthorize = () => {
    setAuthLoading(true);
    login();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className="bg-white shadow-lg min-w-[480px] border border-gray-300"
        style={{
          boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
        }}
      >
        <div className="pt-6 px-8 pb-6">
          {/* popup heading */}
          <div className="flex justify-between">
            <div className="flex gap-2">
              <p className="text-base leading-5 font-extrabold">
                Connect with DV360
              </p>
            </div>
            <button onClick={handleFalseAction} className="h-5 w-5 text-center">
              <IoClose size={20} />
            </button>
          </div>
          {/* body */}
          <div className="my-5">
            <p className="text-sm">Authorize your DV360 account</p>
            <div className="gap-2 mt-2">
              <button
                className="border-[0.75px] border-[#286043] text-[#286043] text-sm font-semibold py-[6px] px-3 w-fit flex items-center"
                onClick={handleClickAuthorize}
              >
                {authLoading ? (
                  <div className="flex gap-2 items-center">
                    <Loader />
                    Authorizing...
                  </div>
                ) : isAuthorized ? (
                  <>
                    <GreenTick />
                    Authorized
                  </>
                ) : (
                  <>
                    <img
                      src={Google_logo}
                      alt="google"
                      className="h-6 w-auto mr-1"
                    />
                    Authorize
                  </>
                )}
              </button>
            </div>
            <div className="mt-3">
              <p className="text-sm">Select Partner Account</p>
              <SingleSelectDropdown
                items={partnerList.map((partner) => partner.name)}
                selectedItem={selectedPartnerAccount}
                setSelectedItem={setSelectedPartnerAccount}
                disabled={!isAuthorized}
              />
            </div>
            <div className="mt-3">
              <p className="text-sm">Select Time Zone</p>
              <SingleSelectDropdown
                items={standardTimeZones}
                selectedItem={selectedTimeZone}
                setSelectedItem={setSelectedTimeZone}
                disabled={!isAuthorized}
              />
            </div>
          </div>
          {/* action button */}
          <div className="flex justify-end items-center gap-2 mt-4">
            <button
              className={`border-[0.75px] border-[#286043] ${
                isAuthorized ? "bg-[#286043]" : "bg-gray-200"
              } ${
                isAuthorized ? "text-white" : "text-gray-500"
              } text-sm font-semibold py-[6px] px-3 h-8 flex items-center mt-4`}
              onClick={handleSaveBtn}
              disabled={!isAuthorized}
            >
              {isSaveLoading ? (
                <div className="flex items-center gap-2">
                  <Loader loaderColor="white" />
                  <p>Saving</p>
                </div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectToDBPopup;
