import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { PopupFieldOption, Rule } from "../../interfaces/rules";
import SingleSelectDropdown from "../SingleSelectDropdown";
import MultiSelectDropdown from "../MultiSelectDropdown";
import toast from "react-hot-toast";
import ChannelPopup from "./ChannelPopup";
import ViewabilityPopup from "./ViewabilityPopup";

interface PopupComponentProps {
  popupData: Rule;
  onClose: () => void;
  handleSaveOnPopup: (item: any) => void;
}

const RuleLibraryActionPopup: React.FC<PopupComponentProps> = ({
  popupData,
  onClose,
  handleSaveOnPopup,
}) => {
  const [formValues, setFormValues] = useState<{
    [key: string]: { dropdown?: string; input?: string };
  }>({});

  const [levels, setLevels] = useState<string[]>([]);
  const [selectedLevels, setSelectedLevels] = useState<string[]>([]);
  const [goalKPIOptions, setGoalKPIOptions] = useState<string[]>([]);
  const [isCustomKPIVisible, setIsCustomKPIVisible] = useState(false);
  const [selectedCriticality, setSelectedCriticality] = useState<string | null>(
    ""
  );

  const [multiSelectDropdown, setMultiSelectDropdown] = useState<string[]>([]);
  const [multiSelectDisplayName, setMultiSelectDisplayName] =
    useState<string>("");
  const [showCountPercentageInput, setShowCountPercentageInput] =
    useState(false);

  useEffect(() => {
    if (popupData.popup_fields) {
      // Set initial options for Goal KPI if the first field is already populated
      const campaignGoal = formValues["Select campaign goal"]?.dropdown;
      updateGoalKPIOptions(campaignGoal);
    }
  }, [formValues]);

  // Update Goal KPI options based on selected campaign goal
  const updateGoalKPIOptions = (campaignGoal: string | undefined) => {
    const goalKPIField = popupData.popup_fields?.find(
      (field) => field.display_name === "Select Goal KPI"
    );
    if (goalKPIField && goalKPIField.dependencies) {
      const newOptions = goalKPIField.dependencies[campaignGoal || ""] || [];
      setGoalKPIOptions(newOptions);

      // If "Other" is included in the options, show the custom KPI input
      setIsCustomKPIVisible(newOptions.includes("Other"));
    }
  };

  const handleInputChange = (name: string, value: any, type: string) => {
    setFormValues((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        [type]: value,
      },
    }));

    // Update Goal KPI options when the campaign goal is selected
    if (name === "Select campaign goal") {
      updateGoalKPIOptions(value);
    }
  };

  useEffect(() => {
    const initialSelectedLevels: string[] = [];
    if (popupData.campaign) initialSelectedLevels.push("Campaign");
    if (popupData.insertion_order)
      initialSelectedLevels.push("Insertion_Order");
    if (popupData.line_item) initialSelectedLevels.push("Line_Item");
    if (popupData.creative) initialSelectedLevels.push("Creative");
    if (popupData.ad_group) initialSelectedLevels.push("Ad_Group");
    if (popupData.ad) initialSelectedLevels.push("Ad");

    setLevels(initialSelectedLevels);
    setSelectedLevels(initialSelectedLevels);

    if (popupData.criticality) {
      setSelectedCriticality(popupData.criticality);
    }

    if (popupData.popup_fields) {
      const campaignGoal = formValues["Select campaign goal"]?.dropdown;
      updateGoalKPIOptions(campaignGoal);
    }
  }, [popupData, formValues]);

  // Limited to one multiSelect only
  useEffect(() => {
    const multiSelectField = popupData.popup_fields?.find(
      (field) => field.type[0] === "dropdown(multi)"
    );
    if (multiSelectField) {
      setMultiSelectDisplayName(multiSelectField.display_name);
    }
  }, [popupData.popup_fields]);

  useEffect(() => {
    if (multiSelectDisplayName) {
      handleInputChange(
        multiSelectDisplayName,
        multiSelectDropdown,
        "dropdown"
      );
    }
    setShowCountPercentageInput(multiSelectDropdown.includes("Count %"));
  }, [multiSelectDropdown, multiSelectDisplayName]);

  const renderPopupBody = (inputField: PopupFieldOption) => {
    const { display_name, type, options } = inputField;
    const currentDropdownValue = formValues[display_name]?.dropdown || "";
    const currentInputValue = formValues[display_name]?.input || "";

    if (display_name === "Select Goal KPI" && goalKPIOptions.length > 0) {
      return (
        <div className="flex items-center gap-2 justify-between mt-2">
          <span className="text-sm w-[120px]">{display_name}</span>
          <div className="w-full">
            <SingleSelectDropdown
              items={goalKPIOptions}
              selectedItem={currentDropdownValue}
              setSelectedItem={(value) =>
                handleInputChange(display_name, value, "dropdown")
              }
            />
          </div>
        </div>
      );
    }

    if (
      display_name === "3rd Party verifier" &&
      type[0] === "dropdown(multi)"
    ) {
      return (
        <div className="flex items-center gap-2 justify-between mt-2">
          <span className="text-sm w-[120px]">{display_name}</span>
          <div className="w-full">
            <MultiSelectDropdown
              items={options || []}
              selectedItems={multiSelectDropdown}
              setSelectedItems={setMultiSelectDropdown}
            />
          </div>
        </div>
      );
    }

    // Handle the special case for count percentage input
    if (
      display_name === "Specify count percentage of post view and post click"
    ) {
      // Only render this input field if "Count %" is selected
      if (!showCountPercentageInput) {
        return null;
      }

      return (
        <div className="flex items-start gap-2 justify-between mt-2">
          <p className="text-sm w-[123px]">{display_name}</p>
          <input
            type="text"
            placeholder="Value"
            className="w-full h-10 border-[0.5px] border-gray-300 p-3 text-sm mt-2 outline-none"
            value={currentInputValue}
            onChange={(e) =>
              handleInputChange(display_name, e.target.value, "input")
            }
          />
        </div>
      );
    }

    if (type.length > 1) {
      return (
        <div className="flex items-center gap-2 justify-between">
          <span className="text-sm">{display_name}</span>
          <div className="w-[200px]">
            {type[0] === "dropdown" && (
              <SingleSelectDropdown
                items={options || []}
                selectedItem={currentDropdownValue}
                setSelectedItem={(value) =>
                  handleInputChange(display_name, value, "dropdown")
                }
              />
            )}
          </div>
          {type[1] === "input" && (
            <input
              type="text"
              placeholder="Value"
              className="w-[290px] h-10 border-[0.5px] border-gray-300 p-3 text-sm mt-3 outline-none"
              value={currentInputValue}
              onChange={(e) =>
                handleInputChange(display_name, e.target.value, "input")
              }
            />
          )}
        </div>
      );
    } else {
      if (type[0] === "dropdown") {
        return (
          <div className="flex items-center gap-2 justify-between mt-2">
            <span className="text-sm w-[120px]">{display_name}</span>
            <div className="w-full">
              <SingleSelectDropdown
                items={options || []}
                selectedItem={currentDropdownValue}
                setSelectedItem={(value) =>
                  handleInputChange(display_name, value, "dropdown")
                }
              />
            </div>
          </div>
        );
      } else if (type[0] === "dropdown(multi)") {
        return (
          <div className="flex items-center gap-2 justify-between mt-2">
            <span className="text-sm w-[120px]">{display_name}</span>
            <div className="w-full">
              <MultiSelectDropdown
                items={options || []}
                selectedItems={multiSelectDropdown}
                setSelectedItems={setMultiSelectDropdown}
              />
            </div>
          </div>
        );
      } else if (type[0] === "input(time)") {
        return (
          <div className="flex items-start gap-2 justify-between mt-2">
            <p className="text-sm w-[123px]">{display_name}</p>
            <input
              type="time"
              placeholder="Enter Time"
              className="w-full h-10 border-[0.5px] border-gray-300 p-3 text-sm mt-2 outline-none"
              value={currentInputValue}
              onChange={(e) =>
                handleInputChange(display_name, e.target.value, "input")
              }
            />
          </div>
        );
      } else if (type[0] === "input(number)") {
        return (
          <div className="flex items-start gap-2 justify-between mt-2">
            <p className="text-sm w-[123px]">{display_name}</p>
            <input
              type="number"
              placeholder="Values (in number)"
              className="w-full h-10 border-[0.5px] border-gray-300 p-3 text-sm mt-2 outline-none"
              value={currentInputValue}
              onChange={(e) =>
                handleInputChange(display_name, e.target.value, "input")
              }
            />
          </div>
        );
      } else {
        return (
          <div className="flex items-start gap-2 justify-between mt-2">
            <p className="text-sm w-[123px]">{display_name}</p>
            <input
              type="text"
              placeholder="Value"
              className="w-full h-10 border-[0.5px] border-gray-300 p-3 text-sm mt-2 outline-none"
              value={currentInputValue}
              onChange={(e) =>
                handleInputChange(display_name, e.target.value, "input")
              }
            />
          </div>
        );
      }
    }
  };

  const render3rdPartyVerifierInput = () => {
    return multiSelectDropdown.map((selectedOption) => (
      <div
        key={selectedOption}
        className="flex items-start gap-2 justify-between mt-2"
      >
        <p className="text-sm w-[123px]">
          Custom Segment ID for {selectedOption}
        </p>
        <input
          type="text"
          placeholder="Enter Segment ID"
          className="w-full h-10 border-[0.5px] border-gray-300 p-3 text-sm mt-2 outline-none"
          value={
            formValues[`Custom Segment ID for ${selectedOption}`]?.input || ""
          }
          onChange={(e) =>
            handleInputChange(
              `Custom Segment ID for ${selectedOption}`,
              e.target.value,
              "input"
            )
          }
        />
      </div>
    ));
  };

  const renderPopup = (
    heading: string | undefined,
    fields: PopupFieldOption[] | undefined
  ) => {
    if (heading === "Custom channel list") {
      return <ChannelPopup setFormValue={setFormValues} />;
    } else if (heading === "Viewability standard") {
      return <ViewabilityPopup setFormValue={setFormValues} />;
    } else {
      return fields?.map((item, index) => {
        if (item.display_name === "3rd Party verifier") {
          return (
            <div key={item.display_name || index}>
              {renderPopupBody(item)}
              {render3rdPartyVerifierInput()}{" "}
              {/* Dynamic inputs for each selected option */}
            </div>
          );
        }
        return (
          <div key={item.display_name || index}>{renderPopupBody(item)}</div>
        );
      });
    }
  };

  const handleSave = () => {
    if (selectedLevels.length === 0) {
      toast.error("Select at least one level");
      return;
    }
    const selectedLevelsObject = {
      selected_level: selectedLevels,
    };
    const fields_data = Object.keys(formValues).map((key) => {
      const dropdownValue = formValues[key]?.dropdown || "";
      const inputValue = formValues[key]?.input || "";
      return { [key]: `${dropdownValue}${inputValue}` };
    });
    const data = {
      id: popupData.id,
      check_name: popupData.check_name,
      ...selectedLevelsObject,
      fields_data,
      criticality: selectedCriticality,
      dsp_name: popupData.dsp_name,
      channel_name: popupData.channel_name,
    };

    handleSaveOnPopup(data);
    setMultiSelectDisplayName("");
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className="bg-white shadow-lg min-w-[480px] max-w-[660px] border border-gray-300"
        style={{
          boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
        }}
      >
        <div className="pt-6 px-8 pb-6">
          {/* popup heading */}
          <div className="flex justify-between mb-6">
            <div className="flex gap-2">
              <p className="text-base leading-5 font-extrabold">
                {popupData.popup_heading}
              </p>
            </div>
            <button onClick={onClose} className="h-5 w-5 text-center">
              <IoClose size={20} />
            </button>
          </div>
          {/* body */}
          <div className="flex items-center gap-3">
            <p className="text-sm w-[115px]">Select Levels</p>
            <div className="w-full">
              <MultiSelectDropdown
                items={levels}
                selectedItems={selectedLevels}
                setSelectedItems={setSelectedLevels}
              />
            </div>
          </div>
          {renderPopup(popupData.popup_heading, popupData.popup_fields)}
          <div className="flex items-center gap-3">
            <p className="text-sm w-[115px]">Select Criticality</p>
            <div className="w-full">
              <SingleSelectDropdown
                items={["Threat", "Warning"]}
                selectedItem={selectedCriticality}
                setSelectedItem={setSelectedCriticality}
              />
            </div>
          </div>
          {/* action button */}
          <div className="flex justify-end items-center gap-2 mt-4">
            <button
              className="p-2 border text-[#286043] border-[#286043] text-sm leading-5 font-[600]"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RuleLibraryActionPopup;
