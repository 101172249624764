import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import MultiSelectDropdown from "../MultiSelectDropdown";
import toast from "react-hot-toast";

interface RequestNewRulePopupProps {
  closePopup: () => void;
  handleRequestNewRuleSubmitBtn: ({
    check_title,
    selected_levels,
    data_source,
  }: {
    check_title: string;
    selected_levels: string[];
    data_source: string;
  }) => void;
}

const RequestNewRulePopup: React.FC<RequestNewRulePopupProps> = ({
  closePopup,
  handleRequestNewRuleSubmitBtn,
}) => {
  const [checkTitle, setCheckTitle] = useState<string>("");
  const [selectedLevels, setSelectedLevels] = useState<string[]>([]);
  const [dataSource, setDataSource] = useState<string>("");

  const handleFalseAction = () => {
    closePopup();
  };

  const handleRequestNewRule = () => {
    if (checkTitle === "" || selectedLevels.length === 0 || dataSource === "") {
      toast.error("Please Fill all the fields");
      return;
    }
    setTimeout(() => {
      handleRequestNewRuleSubmitBtn({
        check_title: checkTitle,
        selected_levels: selectedLevels,
        data_source: dataSource,
      });

      toast.success("Successfully Submitted the Request");
      closePopup();
    }, 1500);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        className="bg-white shadow-lg min-w-[480px] border border-gray-300"
        style={{
          boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
        }}
      >
        <div className="pt-6 px-8 pb-6">
          {/* popup heading */}
          <div className="flex justify-between">
            <div className="flex gap-2">
              <p className="text-base leading-5 font-extrabold">
                Request New Rule
              </p>
            </div>
            <button onClick={handleFalseAction} className="h-5 w-5 text-center">
              <IoClose size={20} />
            </button>
          </div>
          {/* body */}
          <div className="my-5">
            <div className="mb-6">
              <h3 className="text-sm font-semibold mb-4">Enter Check Title</h3>
              <input
                type="text"
                className="border outline-none p-3 w-full h-[42px] text-sm"
                placeholder="Title Check"
                value={checkTitle}
                onChange={(e) => setCheckTitle(e.target.value)}
              />
            </div>
            <div className="mb-6">
              <h3 className="text-sm font-semibold mb-4">
                Select Check Levels
              </h3>
              <MultiSelectDropdown
                items={["Campaign", "Insertion Order", "Line Item", "Creative"]}
                selectedItems={selectedLevels}
                setSelectedItems={setSelectedLevels}
              />
            </div>
            <div className="mb-6">
              <h3 className="text-sm font-semibold mb-4">Enter Data Source</h3>
              <input
                type="text"
                className="border outline-none p-3 w-full h-[42px] text-sm"
                placeholder="Title Check"
                value={dataSource}
                onChange={(e) => setDataSource(e.target.value)}
              />
            </div>
          </div>
          {/* action button */}
          <div className="flex justify-end items-center gap-2 mt-4">
            <button
              className="p-2 text-[#286043] text-sm leading-5 font-[600] border border-[#286043]"
              onClick={handleRequestNewRule}
            >
              Request
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestNewRulePopup;
