import React, { lazy, Suspense, useEffect, useState, useCallback } from "react";
import Sidebar from "../components/Sidebar";
import filter_lines from "../assets/filter_lines.png";
import { IoClose, IoFilterOutline } from "react-icons/io5";
import campaign_page_log from "../assets/campaign_page_logo.png";
import campaign_data from "../json/tables/Campaigns.json";
import Loader from "../components/statusIndicator/loader";
import { useAuth } from "../context/AuthContext";
import UserAvatar from "../components/userAvatar/UserAvatar";
import { Campaign, FilterState } from "../interfaces/campaigns";
import SkeletonTable from "../components/Skeleton";
import NoActionsFound from "../components/noActionsFound";
import ConnectToDBPopup from "../components/ConnectToDBPopup";
import axiosInstance from "../utils/axiosInstance";
import toast from "react-hot-toast";
import dataSwitch from "../json/switch.json";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { API_BASE_URL } from "../constants";
import Cookies from "js-cookie";

const CampaignsTable = lazy(() => import("../components/CampaignsTable"));
const CampaignFilterPopup = lazy(
  () => import("../components/CampaignsFilterPopup")
);

type SortOption =
  | "trader__name"
  | "budget ( ASC )"
  | "health_score ( ASC )"
  | " budget ( DESC )"
  | "health_score ( DESC )";

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const initialFilterValue: FilterState = {
  user: [],
  advertiser: [],
  health_indicator: "",
  criticality: [], // Initialize as integer
};

const initialUrlPath = "/checks/campaigns";

const Campaigns: React.FC = () => {
  const [showFilterPopup, setShowFilterPopup] = useState<boolean>(false);
  const [filter, setFilter] = useState<FilterState>(initialFilterValue);
  const [allAdvertisers, setAllAdvertisers] = useState<string[]>([]);
  const [allFiltercampaigns, setAllFiltercampaigns] = useState<string[]>([]);
  const [filterList, setFilterList] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [allCampaigns, setAllCampaigns] = useState<Campaign[] | undefined>();
  const [nextBtnLink, setNextBtnLink] = useState<string | null>(null);
  const [perviousBtnLink, setPerviousBtnLink] = useState<string | null>(null);
  const [filteredCampaigns, setFilteredCampaigns] = useState<
    Campaign[] | undefined
  >();
  const [activeLink, setActiveLink] = useState<string>(initialUrlPath);
  const [totalCampaigns, setTotalCampaigns] = useState<number>(0);
  const [pageCount, setPageCount] = useState<string>("1");
  const [sortOption, setSortOption] = useState<SortOption>();

  const { accessToken, refreshToken, refreshAccessToken, fullName } = useAuth();
  const [loadTable, setLoadTable] = useState<boolean>(false);
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const [showConnectedToDBPopup, setShowConnectedToDBPopup] = useState<boolean>(
    !(Cookies.get("connectedToDB") === "true")
  );

  const ApplyFilter = async () => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
      return;
    }

    try {
      setLoadTable(true);

      const response = await axiosInstance.get(initialUrlPath, {
        params: {
          advertiser_ids: filter.advertiser
            .map((item) => item.advertiser_id)
            .join(","),
          traders: filter.user.join(","),
          health_indicator: filter.health_indicator,
          criticality: filter.criticality.join(","),
          order: sortOption,
        },
      });

      if (response.status === 200) {
        setAllCampaigns(response.data.data.results);
        setNextBtnLink(response.data.data.next);
        setPerviousBtnLink(response.data.data.prev);
        setTotalCampaigns(response.data.data.count);

        const pageParam = Number(
          new URL(response.data.data.next).searchParams.get("page")
        );

        if (pageParam >= 3) {
          setPageCount((pageParam - 1).toString());
        } else {
          setPageCount("1");
        }
      } else {
        toast.error("Error fetching data, please try again.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadTable(false);
    }
  };

  const ResetFilter = () => {
    // filter api data to initial state.
    setFilter(initialFilterValue);
    window.location.reload();
  };

  const processLink = (link: string | null) => {
    if (link && typeof link === "string") {
      const baseURL = "https://api.beta.atomicads.ai/api";

      if (link.startsWith(baseURL)) {
        return link.replace(baseURL, "");
      } else {
        return link;
      }
    } else {
      return null;
    }
  };

  const updateFilter = (key: keyof typeof filter, value: string | number) => {
    setFilter((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const updateAdvertiser = (
    newAdvertisers: { advertiser_name: string; advertiser_id: string }[]
  ) => {
    setFilter((prev) => {
      // Create a map of the current advertisers for easy lookup
      const currentAdvertiserMap = new Map(
        prev.advertiser.map((adv) => [adv.advertiser_id, adv])
      );

      // Merge the new advertisers, replacing or adding them as necessary
      const updatedAdvertisers = newAdvertisers.map((newAdv) => ({
        advertiser_name: newAdv.advertiser_name,
        advertiser_id: newAdv.advertiser_id,
      }));

      return {
        ...prev,
        advertiser: updatedAdvertisers, // Replace the advertiser list with the new one
      };
    });
  };

  const handleMultiSelectChange = (
    key: "user" | "criticality",
    selectedOptions: Array<string | number>
  ) => {
    setFilter((prev) => ({
      ...prev,
      [key]: selectedOptions,
    }));
  };

  const getPageNumberFromUrl = (url: string) => {
    const activeUrl = API_BASE_URL + url;
    try {
      const pageParam = new URL(activeUrl).searchParams.get("page");
      setPageCount(pageParam ? pageParam : "1");
    } catch (error) {
      console.error("Invalid URL provided:", url, error);
      return null;
    }
  };

  // Fetch campaigns data
  const fetchCampaigns = async () => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
      return;
    }
    setLoadTable(true);
    if (dataSwitch.use_demo_data_for_campaigns) {
      setTimeout(() => {
        setAllCampaigns(campaign_data);
        setLoadTable(false);
      }, 300);
    } else {
      try {
        const response = await axiosInstance.get(activeLink);
        if (response.status === 200) {
          setAllCampaigns(response.data.data.results);
          setNextBtnLink(processLink(response.data.data.next));
          setPerviousBtnLink(processLink(response.data.data.previous));
          setTotalCampaigns(response.data.data.count);
          getPageNumberFromUrl(activeLink);
        } else {
          throw new Error("Failed to fetch campaign data");
        }
      } catch (error: any) {
        toast.error(
          error.response?.data?.message ||
            "Error fetching campaign data, please try again."
        );
      } finally {
        setLoadTable(false);
      }
    }
  };

  useEffect(() => {
    ApplyFilter();
  }, [sortOption]);

  const fetchFilterData = async () => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
      return;
    }

    try {
      const response = await axiosInstance.get("checks/get-filter-data");

      if (response.status === 200) {
        setAllAdvertisers(response.data.data.advertisers_data);
        setAllFiltercampaigns(response.data.data.campaigns_data);
      } else {
        throw new Error("Failed to fetch filter data");
      }
    } catch (error) {
      console.error("Error fetching filter data:", error);
    }
  };

  useEffect(() => {
    fetchFilterData();
  }, [accessToken, refreshToken]);

  useEffect(() => {
    fetchCampaigns();
  }, [accessToken, refreshToken, activeLink]);

  // Sort and filter campaigns based on search query and sort option
  const filterAndSortCampaigns = useCallback(() => {
    if (!allCampaigns) return;

    let results = allCampaigns.filter((campaign) =>
      campaign.name.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
    );

    setFilteredCampaigns(results);
  }, [allCampaigns, debouncedSearchQuery, sortOption]);

  useEffect(() => {
    filterAndSortCampaigns();
  }, [debouncedSearchQuery, sortOption, allCampaigns, filterAndSortCampaigns]);

  const closeFilterPopup = () => {
    setShowFilterPopup(false);
  };

  const removeFilterOneByOne = (item: string) => {
    const result = filterList.filter((element) => element !== item);
    setFilterList(result);
  };

  const handleCloseConnectToDBPopup = () => {
    // localStorage.setItem("hasClosedDBPopup", "true");
    setShowConnectedToDBPopup(false);
  };

  const handleLoadMoreBtn = (btnText: string) => {
    const updateUrlAndPageCount = (link: string | null) => {
      if (link) {
        try {
          const relativeUrl = link.substring(link.indexOf("/checks"));
          setActiveLink(relativeUrl);
        } catch (error) {
          console.error("Error updating URL and page count:", error);
        }
      }
    };

    const normalizedText = btnText.toLowerCase();

    if (normalizedText === "next" && nextBtnLink) {
      updateUrlAndPageCount(nextBtnLink);
    } else if (normalizedText === "previous" && perviousBtnLink) {
      updateUrlAndPageCount(perviousBtnLink);
    } else if (normalizedText === "first_page") {
      const cleanedUrl = activeLink
        .replace(/([&?])page=[^&]*/, "$1")
        .replace(/[\?&]$/, "");
      setActiveLink(cleanedUrl);
      setPageCount("1");
    } else {
      console.warn(`Unhandled button text: ${btnText}`);
    }
  };

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="campaigns" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        {fullName && <UserAvatar />}
        <div className="flex justify-between items-end mt-4">
          <div className="flex justify-start items-center gap-4 mt-4">
            <div
              className="w-11 h-11 border border-solid flex justify-center items-center bg-[#FFF8E4]"
              style={{
                borderWidth: "0.5px",
                borderImageSource:
                  "linear-gradient(141.57deg, #FCD447 7.54%, rgba(93, 220, 248, 0) 102.53%)",
                borderImageSlice: 1,
              }}
            >
              <img
                src={campaign_page_log}
                alt="opportunities logo"
                className="w-[17.5px] h-[17.5px]"
              />
            </div>
            <div>
              <p className="text-xl font-[700] leading-6 text-left">
                Campaigns
              </p>
              <p className="text-gray-500 text-sm leading-4 text-left font-[600]">
                keep your campaigns on track
              </p>
            </div>
          </div>
        </div>
        <div className="border border-l-0 border-r-0 border-t-[0.3px] border-b-[0.3px] mt-6 py-4 flex justify-between items-center">
          <div className="flex items-center gap-3">
            <input
              type="search"
              id="search_campaigns"
              className="text-gray-600 h-12 border-gray-500 border-[0.5px] outline-none p-2 text-xs w-64"
              placeholder="Search campaigns"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="flex items-center justify-end gap-4">
            <div className="flex items-center justify-end gap-1 text-sm leading-4 text-left font-semibold border border-[#286043] p-3 h-12">
              <IoFilterOutline size={18} />
              <p className="text-sm leading-4 text-left font-semibold">
                Sort By :
              </p>
              <select
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value as SortOption)}
                className="h-11 outline-none cursor-pointer"
              >
                <option value="trader__name">Trader Name</option>
                <option value="budget ( ASC )">Budget ( ASC )</option>
                <option value="health_score ( ASC )">
                  Health Score ( ASC )
                </option>
                <option value="budget ( DESC )">Budget ( DESC )</option>
                <option value="health_score ( DESC )">
                  Health Score ( DESC )
                </option>
              </select>
            </div>
            <button
              className="border border-[#286043] p-3 flex justify-center items-center gap-1 h-12"
              onClick={() => setShowFilterPopup(true)}
            >
              <p className="text-sm leading-4 text-left font-[600] text-[#286043]">
                Filter
              </p>
              <img src={filter_lines} alt="three_lines" className="w-[15px]" />
            </button>
          </div>
        </div>
        {filterList.length > 0 && (
          <div className="flex justify-between my-3 gap-3 items-center">
            <ul className="flex items-center gap-2 flex-wrap">
              {filterList.map((item, index) => (
                <li
                  key={index}
                  className="border-[0.5px] p-1 text-xs font-normal flex items-center gap-1"
                >
                  {item}{" "}
                  <button onClick={() => removeFilterOneByOne(item)}>
                    <IoClose />
                  </button>
                </li>
              ))}
            </ul>
            <button
              className="text-sm font-semibold text-[#286043] border border-[#286043] p-3 h-[42px] max-w-24 min-w-24"
              onClick={() => setFilterList([])}
            >
              Clear All
            </button>
          </div>
        )}
        {/* campaigns table section */}
        {loadTable ? (
          <SkeletonTable />
        ) : filteredCampaigns?.length ? (
          <Suspense fallback={<Loader />}>
            <CampaignsTable tableData={filteredCampaigns} />
            {/* next previous button container */}
            <div className="flex gap-2 justify-between mt-5 mb-10">
              <div>
                <div className="border border-[#286043] p-3 flex justify-center items-center gap-1">
                  <p className="text-sm leading-4 text-left font-semibold text-[#286043]">
                    Page : {pageCount} of{" "}
                    {totalCampaigns &&
                      Math.round(Math.ceil(totalCampaigns / 10))}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                {Number(pageCount) > 2 && (
                  <button
                    className="border bg-[#286043] border-[#286043] p-3 flex justify-center items-center gap-1"
                    onClick={() => handleLoadMoreBtn("first_page")}
                  >
                    <span className="text-sm leading-4 text-left font-[600]  text-white">
                      <MdKeyboardDoubleArrowLeft />
                    </span>
                  </button>
                )}
                {perviousBtnLink !== null && (
                  <button
                    className="border bg-[#286043] border-[#286043] p-3 flex justify-center items-center gap-1"
                    onClick={() => handleLoadMoreBtn("previous")}
                  >
                    <span className="text-sm leading-4 text-left font-[600]  text-white">
                      Previous Page
                    </span>
                  </button>
                )}
                {nextBtnLink !== null && (
                  <button
                    className="border bg-[#286043] border-[#286043] p-3 flex justify-center items-center gap-1"
                    onClick={() => handleLoadMoreBtn("next")}
                  >
                    <span className="text-sm leading-4 text-left font-[600]  text-white">
                      Next Page
                    </span>
                  </button>
                )}
              </div>
            </div>
          </Suspense>
        ) : (
          <NoActionsFound sectionName="" errorHeading="No Campaigns Found" />
        )}
        {showFilterPopup && (
          <Suspense fallback={<Loader />}>
            <CampaignFilterPopup
              isOpen={showFilterPopup}
              filter={filter}
              applyFilter={ApplyFilter}
              resetFilter={ResetFilter}
              onClose={closeFilterPopup}
              updateFilter={updateFilter}
              updateAdvertiser={updateAdvertiser}
              handleMultiSelectChange={handleMultiSelectChange}
              allAdvertisers={allAdvertisers}
            />
          </Suspense>
        )}
      </div>
      {showConnectedToDBPopup && (
        <ConnectToDBPopup closePopup={handleCloseConnectToDBPopup} />
      )}
    </div>
  );
};

export default Campaigns;
