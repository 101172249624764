import google_ads_logo from "../assets/connectors_logos/googleads.png";
import vistar_ads_logo from "../assets/connectors_logos/vistar.png";
import double_verify_logo from "../assets/connectors_logos/doubleverify.png";
import google_sheets_logo from "../assets/connectors_logos/googlesheets.png";
import fb_ads_logo from "../assets/connectors_logos/fb.png";
import dv360_ads_logo from "../assets/connectors_logos/dv360.png";
import ttd_ads_logo from "../assets/connectors_logos/ttd.png";


// NOTE : Update the data in the BE also to include the new logo
const logoMap: Record<string, string> = {
  'google ads': google_ads_logo,
  'fb ads': fb_ads_logo,
  'meta ads': fb_ads_logo,
  'dv360': dv360_ads_logo,
  'ttd': ttd_ads_logo,
  'the trade desk': ttd_ads_logo,
  'vistar': vistar_ads_logo,
  'google sheets': google_sheets_logo,
  'double verify': double_verify_logo,
};

export const get_logo_url = (logo: string): string => {
  return logoMap[logo.toLowerCase()] || '';
};
