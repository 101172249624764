// src/AppRoutes.tsx
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Campaigns from "../pages/campaigns";
import Opportunities from "../pages/opportunities";
import Login from "../pages/login";
import LoginVerification from "../pages/loginVerification";
import ConnectorLibrary from "../pages/ConnectorLibrary";
import MyConnectors from "../pages/myConnectors";
import Connector from "../pages/Connector";
import GoogleOAuthWrapper from "../components/GoogleAuthWrapper";
import { AuthProvider } from "../context/AuthContext";
import { IdentifierProvider } from "../context/IdentifierContext";
import ValidateConnector from "../pages/validate-connector";
import Reports from "../pages/reports";
import Products from "../pages/Products";
import User from "../pages/User";
import RuleLibrary from "../pages/RuleLibrary";
import CreateAlerts from "../pages/CreateAlerts";
import CustomCheckRule from "../pages/CustomCheckRule";
import PacingMargin from "../pages/PacingMargin";
import Taxonomy from "../pages/Taxonomy";
import CreateTaxonomy from "../pages/CreateTaxonomy";
import Archive from "../pages/ArchiveOpps";
import { ProfileProvider } from "../context/ProfileContext";
import OnDemandCheck from "../pages/OnDemandCheck";
import TaxonomyRules from "../pages/TaxonomyRules";

const AppRoutes: React.FC = () => (
  <ProfileProvider>
    <AuthProvider>
      <IdentifierProvider>
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login-verification" element={<LoginVerification />} />
          <Route path="/products" element={<Products />} />
          <Route path="/users" element={<User />} />
          <Route path="/connectors-library" element={<ConnectorLibrary />} />
          <Route path="/opportunities" element={<Opportunities />} />
          <Route path="/archive" element={<Archive />} />
          <Route
            path="/campaigns"
            element={
              <GoogleOAuthWrapper>
                <Campaigns />
              </GoogleOAuthWrapper>
            }
          />
          <Route path="/my-connectors" element={<MyConnectors />} />
          <Route path="/validate-connector" element={<ValidateConnector />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/on-demand-check" element={<OnDemandCheck />} />
          <Route path="/rule-library" element={<RuleLibrary />} />
          <Route path="/create-alert" element={<CreateAlerts />} />
          <Route path="/custom-check-rule" element={<CustomCheckRule />} />
          <Route path="/pacing-margin" element={<PacingMargin />} />
          <Route path="/taxonomy" element={<Taxonomy />} />
          <Route path="/create-taxonomy" element={<CreateTaxonomy />} />
          <Route path="/taxonomy-rules" element={<TaxonomyRules />} />
          <Route
            path="/connectors"
            element={
              <GoogleOAuthWrapper>
                <Connector />
              </GoogleOAuthWrapper>
            }
          />
          <Route path="*" element={<Navigate to="/products" replace />} />
        </Routes>
      </IdentifierProvider>
    </AuthProvider>
  </ProfileProvider>
);

export default AppRoutes;
