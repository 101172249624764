import React from "react";

interface Style {
  borderColor: string;
  backgroundColor: string;
  textColor: string;
}
interface CriticalityChipsProps {
  type: string;
  count?: number;
  style: Style;
}

const CriticalityChips: React.FC<CriticalityChipsProps> = ({
  type,
  count,
  style,
}) => {
  const renderCount = () => {
    if (count === 0) {
      return <span>{count}&nbsp;</span>;
    }
    return <span>{count} </span>;
  };

  return (
    <span
      className="border-[0.5px] rounded-xl text-xs font-medium py-1 px-2"
      style={{
        borderColor: style.borderColor,
        backgroundColor: style.backgroundColor,
        color: style.textColor,
      }}
    >
      {count !== undefined && renderCount()}
      {type}
    </span>
  );
};

export default CriticalityChips;
