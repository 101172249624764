import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import UserAvatar from "../components/userAvatar/UserAvatar";
import toast from "react-hot-toast";
import Loader from "../components/statusIndicator/loader";
import { TbAlertHexagon } from "react-icons/tb";
import axiosInstance from '../utils/axiosInstance';

// Types and Interfaces
interface ICondition {
  metric: string;
  operator: string;
  threshold_value: number;
}


interface IConditionGroup {
  operator: string;
  conditions: Array<ICondition>;
  groups?: Array<IConditionGroup>;
}

interface IRule {
  id: number;
  name: string;
  description: string;
  snapshot_type: string;
  entity_id: string | null;
  is_active: boolean;
  time_range_type: string;
  schedule_type: string;
  schedule_days: number[];
  email_notification: boolean;
  email_recipients: string;
  notification_message: string;
  slack_notification: boolean;
  slack_channel: string;
  condition_structure: IConditionGroup;
  custom_start_date?: string;
  custom_end_date?: string;
}

interface IAlert {
  id: number;
  rule: number;
  rule_name: string;
  entity_id: string;
  entity_name: string;
  date_range_start: string;
  date_range_end: string;
  triggered_at: string;
  triggered_conditions: any;
}

interface IEntity {
  id: number;
  name: string;
  campaign_id?: number;
  insertion_order_id?: number;
  deal_id?: number;
  campaign_name?: string;
  insertion_order_name?: string;
}

// Add interface for choices
interface IChoice {
  value: string | number;
  display: string;
}

interface IChoices {
  snapshot_types: IChoice[];
  metrics: IChoice[];
  operators: IChoice[];
  time_range_types: IChoice[];
  schedule_types: IChoice[];
  weekdays: IChoice[];
  group_operators: IChoice[];
}

// Constants
// const BASE_URL = 'http://127.0.0.1:8000/alerts';
const SNAPSHOT_TYPES = ['campaign', 'insertion_order', 'line_item', 'deal_id'];
const TIME_RANGES = [
  'last_24_hours',
  'last_7_days',
  'last_30_days',
  'custom',
  'campaign_duration',
  'all_time'
];
const SCHEDULE_TYPES = ['daily', 'weekly', 'specific_days'];
const METRICS = [
  'Impressions',
  'Clicks',
  'CTR',
  'Cost',
  'CPC',
  'CPM',
  'Conversions',
  'CPA',
  'Viewability',
  'Pacing'
];
const OPERATORS = [
  'greater than',
  'less than',
  'equals',
  'greater than or equal to',
  'less than or equal to'
];

// Add display names for UI
const DISPLAY_NAMES = {
  // Snapshot types
  campaign: 'Campaign',
  insertion_order: 'Insertion Order',
  line_item: 'Line Item',
  deal_id: 'Deal ID',

  // Time ranges
  last_24_hours: 'Last 24 Hours',
  last_7_days: 'Last 7 Days',
  last_30_days: 'Last 30 Days',
  custom: 'Custom Range',
  campaign_duration: 'Campaign Duration',
  all_time: 'All Time',

  // Schedule types
  daily: 'Daily',
  weekly: 'Weekly',
  specific_days: 'Specific Days'
};

// API Service
const alertsApi = {
  async getRules(params?: { is_active?: string; snapshot_type?: string }) {
    const response = await axiosInstance.get('/alerts/rules/', { params });
    return response.data;
  },

  async createRule(ruleData: Partial<IRule>) {
    const response = await axiosInstance.post('/alerts/rules/', ruleData);
    return response.data;
  },

  async getAlerts(params?: { days?: number }) {
    const response = await axiosInstance.get('/alerts/alerts/', { params });
    return response.data;
  },

  async getEntities(type: string) {
    let endpoint;
    switch (type) {
      case 'deal_id':
        endpoint = 'deals';
        break;
      case 'campaign':
        endpoint = 'campaigns';
        break;
      case 'insertion_order':
        endpoint = 'insertion_orders';
        break;
      case 'line_item':
        endpoint = 'line_items';
        break;
      default:
        endpoint = type.toLowerCase().replace(' ', '_') + 's';
    }
    const response = await axiosInstance.get(`/alerts/entities/${endpoint}/`);
    return response.data;
  },

  async getChoices() {
    const response = await axiosInstance.get('/alerts/rules/choices/');
    return response.data;
  },

  async activateRule(ruleId: number) {
    const response = await axiosInstance.post(`/alerts/rules/${ruleId}/activate/`);
    return response.data;
  },

  async deactivateRule(ruleId: number) {
    const response = await axiosInstance.post(`/alerts/rules/${ruleId}/deactivate/`);
    return response.data;
  },

  async updateRule(ruleId: number, ruleData: Partial<IRule>) {
    const response = await axiosInstance.put(`/alerts/rules/${ruleId}/`, ruleData);
    return response.data;
  },
};

// Create a context to store choices
const ChoicesContext = React.createContext<IChoices | null>(null);

// Create a provider component
const ChoicesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [choices, setChoices] = useState<IChoices | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadChoices = async () => {
      try {
        const response = await alertsApi.getChoices();
        setChoices(response.data);
      } catch (error) {
        toast.error('Failed to load choices');
      } finally {
        setLoading(false);
      }
    };
    loadChoices();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!choices) {
    return <div>Failed to load form options. Please refresh the page.</div>;
  }

  return (
    <ChoicesContext.Provider value={choices}>
      {children}
    </ChoicesContext.Provider>
  );
};

// Custom hook to use choices
const useChoices = () => {
  const choices = React.useContext(ChoicesContext);
  if (!choices) {
    throw new Error('useChoices must be used within a ChoicesProvider');
  }
  return choices;
};

// Components
const RulesList: React.FC<{ onEditRule: (rule: IRule) => void }> = ({ onEditRule }) => {
  const [rules, setRules] = useState<IRule[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeFilter, setActiveFilter] = useState<string>('all');
  const [snapshotFilter, setSnapshotFilter] = useState<string>('');
  const choices = useChoices();

  useEffect(() => {
    loadRules();
  }, [activeFilter, snapshotFilter]);

  const loadRules = async () => {
    try {
      const params: { is_active?: string; snapshot_type?: string } = {};

      // Add active/inactive filter
      if (activeFilter !== 'all') {
        params.is_active = activeFilter === 'active' ? 'true' : 'false';
      }

      // Add snapshot type filter
      if (snapshotFilter) {
        params.snapshot_type = snapshotFilter;
      }

      const response = await alertsApi.getRules(params);
      const rulesData = response.data || [];
      setRules(Array.isArray(rulesData) ? rulesData : []);
    } catch (error) {
      toast.error('Failed to load rules');
      setRules([]);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleActive = async (rule: IRule) => {
    try {
      if (rule.is_active) {
        await alertsApi.deactivateRule(rule.id);
        toast.success('Rule deactivated successfully');
      } else {
        await alertsApi.activateRule(rule.id);
        toast.success('Rule activated successfully');
      }
      // Reload rules to get updated status
      loadRules();
    } catch (error) {
      toast.error(`Failed to ${rule.is_active ? 'deactivate' : 'activate'} rule`);
    }
  };

  if (loading) return <div>Loading rules...</div>;

  return (
    <div className="space-y-6">
      {/* Filters Section */}
      <div className="flex gap-4 p-4 bg-gray-50 rounded-lg">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
          <select
            value={activeFilter}
            onChange={(e) => setActiveFilter(e.target.value)}
            className="border rounded-md shadow-sm p-2 text-sm"
          >
            <option value="all">All Rules</option>
            <option value="active">Active Only</option>
            <option value="inactive">Inactive Only</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Type</label>
          <select
            value={snapshotFilter}
            onChange={(e) => setSnapshotFilter(e.target.value)}
            className="border rounded-md shadow-sm p-2 text-sm"
          >
            <option value="">All Types</option>
            {choices.snapshot_types.map(type => (
              <option key={type.value} value={type.value}>
                {type.display}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Rules List */}
      {!Array.isArray(rules) || rules.length === 0 ? (
        <div className="text-center text-gray-500 py-8">No rules found</div>
      ) : (
        <div className="grid gap-4">
          {rules.map(rule => (
            <div key={rule.id} className="border p-4 rounded-lg">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="font-bold">{rule.name}</h3>
                  <p className="text-sm text-gray-600 mt-1">{rule.description}</p>
                  <div className="flex gap-2 mt-2">
                    <span className="text-xs bg-gray-100 px-2 py-1 rounded">
                      {DISPLAY_NAMES[rule.snapshot_type as keyof typeof DISPLAY_NAMES]}
                    </span>
                    <span className="text-xs bg-gray-100 px-2 py-1 rounded">
                      Entity: {rule.entity_id ? `ID: ${rule.entity_id}` : 'All'}
                    </span>
                    <span className={`text-xs px-2 py-1 rounded ${rule.is_active
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800'
                      }`}>
                      {rule.is_active ? 'Active' : 'Inactive'}
                    </span>
                  </div>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => handleToggleActive(rule)}
                    className={`text-sm px-3 py-1 rounded ${rule.is_active
                        ? 'bg-red-50 text-red-600 hover:bg-red-100'
                        : 'bg-green-50 text-green-600 hover:bg-green-100'
                      }`}
                  >
                    {rule.is_active ? 'Deactivate' : 'Activate'}
                  </button>
                  <button
                    onClick={() => onEditRule(rule)}
                    className="text-sm text-blue-600 hover:underline px-3 py-1"
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const AlertsList: React.FC = () => {
  const [alerts, setAlerts] = useState<IAlert[]>([]);
  const [loading, setLoading] = useState(true);
  const [timeRange, setTimeRange] = useState(7);

  useEffect(() => {
    loadAlerts();
  }, [timeRange]);

  const loadAlerts = async () => {
    try {
      const response = await alertsApi.getAlerts({ days: timeRange });
      // Access the data array from the response
      const alertsData = response.data || [];
      setAlerts(Array.isArray(alertsData) ? alertsData : []);
    } catch (error) {
      toast.error('Failed to load alerts');
      setAlerts([]);
    } finally {
      setLoading(false);
    }
  };

  const formatTriggerConditions = (conditions: any) => {
    return Object.values(conditions).map((condition: any) => {
      const operatorMap: { [key: string]: string } = {
        'gt': '>',
        'lt': '<',
        'eq': '=',
        'gte': '≥',
        'lte': '≤'
      };

      const operator = operatorMap[condition.operator] || condition.operator;

      return (
        <div key={condition.metric} className="flex flex-col space-y-1">
          <div className="text-sm">
            <span className="font-medium">Condition:</span> {condition.metric} {operator} {condition.threshold}
          </div>
          <div className="text-sm text-red-600">
            <span className="font-medium">Current value:</span> {condition.current_value.toFixed(2)}
          </div>
        </div>
      );
    });
  };

  if (loading) return <div>Loading alerts...</div>;

  // Check if alerts is an array and has items
  if (!Array.isArray(alerts) || alerts.length === 0) {
    return <div>No alerts found</div>;
  }

  return (
    <div>
      <div className="mb-4">
        <select
          value={timeRange}
          onChange={(e) => setTimeRange(Number(e.target.value))}
          className="border p-2 rounded"
        >
          <option value={1}>Last 24 hours</option>
          <option value={7}>Last 7 days</option>
          <option value={30}>Last 30 days</option>
        </select>
      </div>

      <div className="grid gap-4">
        {alerts.map(alert => (
          <div key={alert.id} className="border p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow">
            <div className="flex justify-between items-start mb-3">
              <div>
                <h3 className="font-bold text-lg">
                  {alert.rule_name}
                  {/* TODO: Add link to rule */}
                  {/* <a
                    href={`http://127.0.0.1:8000/api/alerts/rules/${alert.rule}/`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-2 text-sm text-blue-600 hover:underline"
                  > */}
                    (Rule #{alert.rule})
                  {/* </a> */}
                </h3>
                <p className="text-sm text-gray-600 mt-1">
                  Entity: {alert.entity_name} (ID: {alert.entity_id})
                </p>
              </div>
              <span className="text-sm text-gray-500">
                {new Date(alert.triggered_at).toLocaleString()}
              </span>
            </div>

            <div className="bg-gray-50 p-3 rounded">
              {formatTriggerConditions(alert.triggered_conditions)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

type FormCondition = {
  id: string;
  metric: string;
  operator: string;
  value: string;
  groupId?: string;
};

const CreateRuleForm: React.FC<{
  initialRule?: IRule;
  onSuccess?: () => void;
}> = ({ initialRule, onSuccess }) => {
  const [name, setName] = useState(initialRule?.name || '');
  const [description, setDescription] = useState(initialRule?.description || '');
  const [snapshotType, setSnapshotType] = useState(initialRule?.snapshot_type || '');
  const [entityId, setEntityId] = useState<string>(initialRule?.entity_id?.toString() || '');
  const [entities, setEntities] = useState<IEntity[]>([]);
  const [timeRange, setTimeRange] = useState(initialRule?.time_range_type || '');
  const [scheduleType, setScheduleType] = useState(initialRule?.schedule_type || 'daily');
  const [groupOperators, setGroupOperators] = useState<Record<string, string>>({});

  // Function to filter metrics based on snapshot type
  const getAvailableMetrics = (choices: IChoices) => {
    if (snapshotType === 'deal_id') {
      // For deal_id, show all metrics including Pacing
      return choices.metrics;
    } else {
      // For other types, show all metrics except Pacing
      return choices.metrics.filter(metric =>
        typeof metric.value === 'string' && metric.value.toLowerCase() !== 'pacing'
      );
    }
  };

  // Update the conditions state to include group information
  const [conditions, setConditions] = useState<FormCondition[]>(
    (() => {
      if (!initialRule?.condition_structure) return [];

      const conditionsList: FormCondition[] = [];

      // Map operator from backend format to frontend format
      const mapOperator = (op: string) => {
        // First check if it's already in frontend format
        const frontendOperators = [
          'Greater Than',
          'Less Than',
          'Equal',
          'Greater Than or Equal',
          'Less Than or Equal'
        ];
        if (frontendOperators.includes(op)) {
          return op;
        }

        // If not, map from backend format
        const operatorMap: { [key: string]: string } = {
          'gt': 'Greater Than',
          'lt': 'Less Than',
          'eq': 'Equal',
          'gte': 'Greater Than or Equal',
          'lte': 'Less Than or Equal'
        };
        return operatorMap[op] || op;
      };

      // Add ungrouped conditions
      initialRule.condition_structure.conditions.forEach((c: any, index) => {
        const mappedOperator = mapOperator(c.operator);
        conditionsList.push({
          id: `condition-${index}`,
          metric: c.metric,
          operator: mappedOperator,
          value: c.threshold_value.toString()
        });
      });

      // Add grouped conditions
      initialRule.condition_structure.groups?.forEach((group, groupIndex) => {
        const groupId = `group-${groupIndex}`;

        // Add conditions from the group
        group.conditions.forEach((c: any, condIndex) => {
          const mappedOperator = mapOperator(c.operator);
          conditionsList.push({
            id: `condition-${groupIndex}-${condIndex}`,
            metric: c.metric,
            operator: mappedOperator,
            value: c.threshold_value.toString(),
            groupId
          });
        });
      });

      return conditionsList;
    })()
  );

  // Initialize group operators in useEffect
  useEffect(() => {
    if (initialRule?.condition_structure?.groups) {
      const initialGroupOperators: Record<string, string> = {};
      initialRule.condition_structure.groups.forEach((group, index) => {
        initialGroupOperators[`group-${index}`] = group.operator;
      });
      setGroupOperators(initialGroupOperators);
    }
  }, [initialRule]);

  // Effect to clear metrics when snapshot type changes
  useEffect(() => {
    if (snapshotType) {
      // Only clear conditions if we're not editing an existing rule
      if (!initialRule) {
        setConditions(prev => {
          const newConditions = prev.map(condition => ({
            ...condition,
            metric: '', // Reset metric
            value: ''   // Reset value
          }));
          return newConditions;
        });
      }
      loadEntities();
    }
  }, [snapshotType, initialRule]);

  const [emailNotification, setEmailNotification] = useState(initialRule?.email_notification || true);
  const [emailRecipients, setEmailRecipients] = useState(initialRule?.email_recipients || '');
  const [notificationMessage, setNotificationMessage] = useState(initialRule?.notification_message || '');
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const choices = useChoices();

  // Update the custom date range state
  const [customDateRange, setCustomDateRange] = useState({
    custom_start_date: initialRule?.custom_start_date || '',
    custom_end_date: initialRule?.custom_end_date || ''
  });

  const [selectedDays, setSelectedDays] = useState<number[]>(
    initialRule?.schedule_days || []
  );

  useEffect(() => {
    if (snapshotType) {
      loadEntities();
    }
  }, [snapshotType]);

  const loadEntities = async () => {
    try {
      const response = await alertsApi.getEntities(snapshotType);
      let entitiesData = response.data || [];

      // If the response has a specific structure for deal_ids
      if (snapshotType === 'deal_id' && Array.isArray(entitiesData)) {
        // Transform the deal_id data structure
        entitiesData = entitiesData.map(item => ({
          id: item.deal_id, // Use deal_id as the id
          name: item.deal_id, // Use deal_id as the name
          deal_id: item.deal_id,
          insertion_orders: item.insertion_orders
        }));
      }

      setEntities(Array.isArray(entitiesData) ? entitiesData : []);
    } catch (error) {
      toast.error('Failed to load entities');
      setEntities([]); // Set empty array on error
    }
  };

  const handleAddCondition = () => {
    setConditions(prev => [
      ...prev,
      {
        id: `condition-${Date.now()}`,
        metric: '',
        operator: '',
        value: ''
      }
    ]);
  };

  const handleAddGroup = () => {
    const groupId = `group-${Date.now()}`;
    setGroupOperators(prev => ({
      ...prev,
      [groupId]: 'AND'
    }));
    setConditions(prev => [
      ...prev,
      {
        id: `condition-${Date.now()}`,
        metric: '',
        operator: '',
        value: '',
        groupId
      }
    ]);
  };

  const handleRemoveCondition = (conditionId: string) => {
    setConditions(prev => prev.filter(c => c.id !== conditionId));
  };

  const handleRemoveGroup = (groupId: string) => {
    setConditions(prev => prev.filter(c => c.groupId !== groupId));
    setGroupOperators(prev => {
      const newOperators = { ...prev };
      delete newOperators[groupId];
      return newOperators;
    });
  };

  // Update the entity display in the select dropdown
  const getEntityDisplayName = (entity: IEntity) => {
    if (snapshotType === 'deal_id' && entity.deal_id) {
      return `Deal ID: ${entity.deal_id}`;
    } else if (snapshotType === 'campaign' && entity.campaign_id) {
      return `${entity.campaign_name || entity.name} (${entity.campaign_id})`;
    } else if (snapshotType === 'insertion_order' && entity.insertion_order_id) {
      return `${entity.insertion_order_name || entity.name} (${entity.insertion_order_id})`;
    } else if (entity.name) {
      return entity.name;
    }
    return 'Unnamed Entity';
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;

    // Check if there are any conditions
    if (conditions.length === 0) {
      toast.error('Please add at least one condition');
      return;
    }

    setIsSubmitting(true);
    setLoading(true);

    try {
      // Map operator from frontend format to backend format
      const mapOperatorToBackend = (op: string) => {
        // First check if it's already in backend format
        const backendOperators = ['gt', 'lt', 'eq', 'gte', 'lte'];
        if (backendOperators.includes(op)) {
          return op;
        }

        // If not, map from frontend format
        const operatorMap: { [key: string]: string } = {
          'Greater Than': 'gt',
          'Less Than': 'lt',
          'Equal': 'eq',
          'Greater Than or Equal': 'gte',
          'Less Than or Equal': 'lte'
        };
        return operatorMap[op] || op;
      };

      // Build the condition structure
      const buildConditionStructure = (): IConditionGroup => {
        // Handle ungrouped conditions
        const ungroupedConditions = conditions
          .filter(c => !c.groupId)
          .map(c => ({
            metric: c.metric,
            operator: mapOperatorToBackend(c.operator),
            threshold_value: parseFloat(c.value)
          }));

        // Handle grouped conditions
        const groups = Object.keys(groupOperators).map(groupId => ({
          operator: groupOperators[groupId],
          conditions: conditions
            .filter(c => c.groupId === groupId)
            .map(c => ({
              metric: c.metric,
              operator: mapOperatorToBackend(c.operator),
              threshold_value: parseFloat(c.value)
            }))
        }));

        // Combine all conditions with the new structure
        return {
          operator: 'AND',
          conditions: ungroupedConditions,
          groups: groups
        };
      };

      const ruleData = {
        name,
        description,
        snapshot_type: snapshotType,
        entity_id: entityId || null,
        time_range_type: timeRange,
        schedule_type: scheduleType,
        ...(timeRange === 'custom' && {
          custom_start_date: customDateRange.custom_start_date,
          custom_end_date: customDateRange.custom_end_date
        }),
        ...(scheduleType === 'specific_days' && {
          schedule_days: selectedDays
        }),
        email_notification: emailNotification,
        email_recipients: emailRecipients,
        notification_message: notificationMessage,
        condition_structure: buildConditionStructure()
      };

      if (initialRule?.id) {
        await alertsApi.updateRule(initialRule.id, ruleData);
        toast.success('Rule updated successfully');
      } else {
        await alertsApi.createRule(ruleData);
        toast.success('Rule created successfully');
      }

      // Reset form and notify parent
      if (!initialRule) {
        setName('');
        setDescription('');
        setSnapshotType('');
        setEntityId('');
        setTimeRange('');
        setScheduleType('daily');
        setConditions([]);
        setEmailNotification(true);
        setEmailRecipients('');
        setNotificationMessage('');
        setCustomDateRange({
          custom_start_date: '',
          custom_end_date: ''
        });
        setSelectedDays([]);
      }

      onSuccess?.();
    } catch (error) {
      toast.error(`Failed to ${initialRule ? 'update' : 'create'} rule`);
    } finally {
      setLoading(false);
      setIsSubmitting(false);
    }
  };

  if (!choices) {
    return <div>Loading form options...</div>;
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700">Rule Name</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="mt-1 block w-full border rounded-md shadow-sm p-2"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Description</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="mt-1 block w-full border rounded-md shadow-sm p-2"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Type</label>
        <select
          value={snapshotType}
          onChange={(e) => {
            setSnapshotType(e.target.value);
            setEntityId(''); // Clear selected entity when type changes
          }}
          className="mt-1 block w-full border rounded-md shadow-sm p-2"
          required
        >
          <option value="">Select type</option>
          {choices.snapshot_types.map(type => (
            <option key={type.value} value={type.value}>
              {type.display}
            </option>
          ))}
        </select>
      </div>

      {snapshotType && (
        <div>
          <label className="block text-sm font-medium text-gray-700">
            {snapshotType === 'deal_id' ? 'Deal ID' : 'Entity'}
          </label>
          <select
            value={entityId}
            onChange={(e) => setEntityId(e.target.value)}
            className="mt-1 block w-full border rounded-md shadow-sm p-2"
          >
            <option value="">All {snapshotType === 'deal_id' ? 'deals' : 'entities'}</option>
            {Array.isArray(entities) && entities.map(entity => (
              <option key={entity.deal_id} value={entity.deal_id}>
                {getEntityDisplayName(entity)}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <label className="block text-sm font-medium text-gray-700">Conditions</label>
          <div className="space-x-2">
            <button
              type="button"
              onClick={handleAddCondition}
              className="text-sm px-3 py-1 bg-blue-50 text-blue-600 rounded hover:bg-blue-100"
            >
              Add Condition
            </button>
            <button
              type="button"
              onClick={handleAddGroup}
              className="text-sm px-3 py-1 bg-green-50 text-green-600 rounded hover:bg-green-100"
            >
              Add Group
            </button>
          </div>
        </div>

        <div className="space-y-4">
          {/* Ungrouped conditions */}
          {conditions.filter(c => !c.groupId).map((condition) => (
            <div key={condition.id} className="flex gap-2 items-center">
              <select
                value={condition.metric}
                onChange={(e) => {
                  setConditions(prev => prev.map(c =>
                    c.id === condition.id ? { ...c, metric: e.target.value } : c
                  ));
                }}
                className="border rounded p-2 flex-1"
                required
              >
                <option value="">Select metric</option>
                {getAvailableMetrics(choices).map(metric => (
                  <option key={metric.value} value={metric.value}>
                    {metric.display}
                  </option>
                ))}
              </select>

              <select
                value={condition.operator}
                onChange={(e) => {
                  setConditions(prev => prev.map(c =>
                    c.id === condition.id ? { ...c, operator: e.target.value } : c
                  ));
                }}
                className="border rounded p-2 w-40"
                required
              >
                <option value="">Select operator</option>
                {choices.operators.map(op => {
                  return (
                    <option key={op.value} value={op.display}>
                      {op.display}
                    </option>
                  );
                })}
              </select>

              <input
                type="number"
                value={condition.value}
                onChange={(e) => {
                  setConditions(prev => prev.map(c =>
                    c.id === condition.id ? { ...c, value: e.target.value } : c
                  ));
                }}
                className="border rounded p-2 w-32"
                placeholder="Value"
                required
              />

              <button
                type="button"
                onClick={() => handleRemoveCondition(condition.id)}
                className="text-red-600 hover:text-red-800 p-2"
              >
                Remove
              </button>
            </div>
          ))}

          {/* Grouped conditions */}
          {Object.keys(groupOperators).map(groupId => (
            <div key={groupId} className="border rounded-lg p-4 bg-gray-50">
              <div className="flex justify-between items-center mb-3">
                <select
                  value={groupOperators[groupId]}
                  onChange={(e) => {
                    setGroupOperators(prev => ({
                      ...prev,
                      [groupId]: e.target.value
                    }));
                  }}
                  className="border rounded p-1 text-sm bg-white"
                >
                  {choices.group_operators.map(op => (
                    <option key={op.value} value={op.value}>
                      {op.display}
                    </option>
                  ))}
                </select>

                <button
                  type="button"
                  onClick={() => handleRemoveGroup(groupId)}
                  className="text-red-600 hover:text-red-800 text-sm"
                >
                  Remove Group
                </button>
              </div>

              <div className="space-y-2">
                {conditions.filter(c => c.groupId === groupId).map((condition) => (
                  <div key={condition.id} className="flex gap-2 items-center">
                    <select
                      value={condition.metric}
                      onChange={(e) => {
                        setConditions(prev => prev.map(c =>
                          c.id === condition.id ? { ...c, metric: e.target.value } : c
                        ));
                      }}
                      className="border rounded p-2 flex-1"
                      required
                    >
                      <option value="">Select metric</option>
                      {getAvailableMetrics(choices).map(metric => (
                        <option key={metric.value} value={metric.value}>
                          {metric.display}
                        </option>
                      ))}
                    </select>

                    <select
                      value={condition.operator}
                      onChange={(e) => {
                        setConditions(prev => prev.map(c =>
                          c.id === condition.id ? { ...c, operator: e.target.value } : c
                        ));
                      }}
                      className="border rounded p-2 w-40"
                      required
                    >
                      <option value="">Select operator</option>
                      {choices.operators.map(op => {
                        return (
                          <option key={op.value} value={op.display}>
                            {op.display}
                          </option>
                        );
                      })}
                    </select>

                    <input
                      type="number"
                      value={condition.value}
                      onChange={(e) => {
                        setConditions(prev => prev.map(c =>
                          c.id === condition.id ? { ...c, value: e.target.value } : c
                        ));
                      }}
                      className="border rounded p-2 w-32"
                      placeholder="Value"
                      required
                    />

                    <button
                      type="button"
                      onClick={() => handleRemoveCondition(condition.id)}
                      className="text-red-600 hover:text-red-800 p-2"
                    >
                      Remove
                    </button>
                  </div>
                ))}

                <button
                  type="button"
                  onClick={() => {
                    setConditions(prev => [
                      ...prev,
                      {
                        id: `condition-${Date.now()}`,
                        metric: '',
                        operator: '',
                        value: '',
                        groupId
                      }
                    ]);
                  }}
                  className="text-sm text-blue-600 hover:text-blue-800"
                >
                  Add Condition to Group
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Time Range</label>
        <select
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value)}
          className="mt-1 block w-full border rounded-md shadow-sm p-2"
          required
        >
          <option value="">Select time range</option>
          {choices.time_range_types.map(range => (
            <option key={range.value} value={range.value}>
              {range.display}
            </option>
          ))}
        </select>

        {/* Custom date range picker */}
        {timeRange === 'custom' && (
          <div className="mt-3 grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Start Date</label>
              <input
                type="date"
                value={customDateRange.custom_start_date}
                onChange={(e) => setCustomDateRange(prev => ({
                  ...prev,
                  custom_start_date: e.target.value
                }))}
                className="mt-1 block w-full border rounded-md shadow-sm p-2"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">End Date</label>
              <input
                type="date"
                value={customDateRange.custom_end_date}
                onChange={(e) => setCustomDateRange(prev => ({
                  ...prev,
                  custom_end_date: e.target.value
                }))}
                className="mt-1 block w-full border rounded-md shadow-sm p-2"
                required
              />
            </div>
          </div>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Schedule</label>
        <select
          value={scheduleType}
          onChange={(e) => setScheduleType(e.target.value)}
          className="mt-1 block w-full border rounded-md shadow-sm p-2"
          required
        >
          {choices.schedule_types.map(type => (
            <option key={type.value} value={type.value}>
              {type.display}
            </option>
          ))}
        </select>

        {/* Add weekday selector */}
        {scheduleType === 'specific_days' && (
          <div className="mt-3">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select Days
            </label>
            <div className="flex flex-wrap gap-2">
              {choices.weekdays.map(day => (
                <label
                  key={day.value}
                  className={`
                    flex items-center justify-center px-3 py-2 rounded-md cursor-pointer
                    ${selectedDays.includes(Number(day.value))
                      ? 'bg-blue-100 text-blue-800 border-blue-300'
                      : 'bg-gray-50 text-gray-700 border-gray-200'
                    } border hover:bg-blue-50 transition-colors
                  `}
                >
                  <input
                    type="checkbox"
                    className="hidden"
                    checked={selectedDays.includes(Number(day.value))}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedDays(prev => [...prev, Number(day.value)]);
                      } else {
                        setSelectedDays(prev =>
                          prev.filter(d => d !== Number(day.value))
                        );
                      }
                    }}
                  />
                  {day.display}
                </label>
              ))}
            </div>
            {selectedDays.length === 0 && (
              <p className="text-sm text-red-600 mt-1">
                Please select at least one day
              </p>
            )}
          </div>
        )}
      </div>

      <button
        type="submit"
        disabled={isSubmitting}
        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
      >
        {isSubmitting ? 'Submitting...' : 'Save Rule'}
      </button>
    </form>
  );
};

// Main Component
const AlertsPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'create' | 'rules' | 'alerts'>('create');
  const [editingRule, setEditingRule] = useState<IRule | undefined>();
  const { accessToken, refreshToken, refreshAccessToken } = useAuth();

  const handleRuleSuccess = () => {
    setEditingRule(undefined);
    setActiveTab('rules');
  };

  useEffect(() => {
    if (!accessToken || !refreshToken) {
      refreshAccessToken();
    }
  }, [accessToken, refreshToken, refreshAccessToken]);

  return (
    <div className="pt-3 pb-3 pr-1 bg-[#F6F9F8]">
      <Sidebar activePage="alerts" />
      <div className="ml-[100px] bg-white min-h-screen p-6">
        <UserAvatar />

        <div className="flex items-center gap-4 mt-4 mb-6">
          <div className="w-11 h-11 border flex items-center justify-center bg-[#E4FAFF]">
            <TbAlertHexagon size={20} />
          </div>
          <div>
            <h1 className="text-xl font-bold">Alerts Management</h1>
            <p className="text-gray-500">Create and manage alert rules</p>
          </div>
        </div>

        <div className="flex gap-4 mb-6 border-b">
          <button
            className={`px-4 py-2 ${activeTab === 'create' ? 'border-b-2 border-blue-500' : ''}`}
            onClick={() => {
              setActiveTab('create');
              setEditingRule(undefined);
            }}
          >
            Create Rule
          </button>
          <button
            className={`px-4 py-2 ${activeTab === 'rules' ? 'border-b-2 border-blue-500' : ''}`}
            onClick={() => setActiveTab('rules')}
          >
            Rules
          </button>
          <button
            className={`px-4 py-2 ${activeTab === 'alerts' ? 'border-b-2 border-blue-500' : ''}`}
            onClick={() => setActiveTab('alerts')}
          >
            Alerts
          </button>
        </div>

        {activeTab === 'create' && (
          <ChoicesProvider>
            <CreateRuleForm
              initialRule={editingRule}
              onSuccess={handleRuleSuccess}
            />
          </ChoicesProvider>
        )}
        {activeTab === 'rules' && (
          <ChoicesProvider>
            <RulesList onEditRule={(rule) => {
              setEditingRule(rule);
              setActiveTab('create');
            }} />
          </ChoicesProvider>
        )}
        {activeTab === 'alerts' && <AlertsList />}
      </div>
    </div>
  );
};

export { AlertsPage as default };
