import axios from "axios";
import { API_BASE_URL } from "../constants";
import Cookies from "js-cookie";

// Description : The `refreshAccessToken()` function retrieves the refresh token from cookies
// and uses it to request a new access token from the backend. If successful, it stores the new access token in cookies and returns it. If the refresh fails
// (e.g., invalid or missing refresh token), it redirects the user to the login page.
const refreshAccessToken = async () => {
  const refreshToken = Cookies.get("refreshToken");
  if (!refreshToken) {
    window.location.href = "/login";
    throw new Error("No refresh token available");
  }

  try {
    const response = await axios.post(
      `${API_BASE_URL}/token/refresh/`,
      { refresh: refreshToken },
      { headers: { "Content-Type": "application/json" } }
    );

    const newAccessToken = response.data.data.access;
    Cookies.set("accessToken", newAccessToken, { expires: 1 / 24 });
    return newAccessToken;
  } catch (error) {
    window.location.href = "/login";
    throw new Error("Token refresh failed");
  }
};

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

let isRefreshing = false;

axiosInstance.interceptors.request.use(
  async (config) => {
    let accessToken = Cookies.get("accessToken");

    if (!accessToken) {
      try {
        accessToken = await refreshAccessToken(); // Refresh the token if missing
      } catch (error) {
        return Promise.reject(error); // Reject if refresh fails
      }
    }

    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Handle 401 error (Unauthorized)
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        // If a token refresh is in progress, wait until it completes
        return new Promise((resolve) => {
          const checkToken = setInterval(() => {
            const newToken = Cookies.get("accessToken");
            if (newToken) {
              clearInterval(checkToken);
              originalRequest.headers.Authorization = `Bearer ${newToken}`;
              resolve(axiosInstance(originalRequest));
            }
          }, 100);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const newAccessToken = await refreshAccessToken();
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

        isRefreshing = false;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        isRefreshing = false;
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
