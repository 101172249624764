import React, { useState, useEffect } from "react";
import SingleSelectDropdown from "../SingleSelectDropdown";
import MultiSelectDropdown from "../MultiSelectDropdown";

interface ChannelPopupProps {
  setFormValue: React.Dispatch<
    React.SetStateAction<{
      [key: string]: { dropdown?: string; input?: string };
    }>
  >;
}

const ChannelPopup: React.FC<ChannelPopupProps> = ({ setFormValue }) => {
  const [isBrokenByEnvironment, setIsBrokenByEnvironment] = useState<
    string | null
  >("Yes");
  const [selectedEnvApp, setSelectedEnvApp] = useState<string | null>("App");
  const [selectedEnvAppValues, setSelectedEnvAppValues] = useState<string[]>(
    []
  );

  const [selectedEnvWeb, setSelectedEnvWeb] = useState<string | null>("Web");
  const [selectedEnvWebValues, setSelectedEnvWebValues] = useState<string[]>(
    []
  );

  const [selectedChannelList, setSelectedChannelList] = useState<string[]>([]);

  const options = [
    "MFA Websites(10738350095)",
    "Regital Master Block list Web(9669830907)",
    "Regital Master Block list App(9673494847)",
    "TAG Pirate App list(10653050726)",
  ];

  // Update the formValue whenever a change is made in any of the fields
  useEffect(() => {
    setFormValue((prevState) => ({
      ...prevState,
      "Is Broken By Environment": { dropdown: isBrokenByEnvironment ?? "" },
      "Env App": { dropdown: selectedEnvApp ?? "" },
      "Env App Channels": { dropdown: selectedEnvAppValues.join(", ") },
      "Env Web": { dropdown: selectedEnvWeb ?? "" },
      "Env Web Channels": { dropdown: selectedEnvWebValues.join(", ") },
      "Channel List": { dropdown: selectedChannelList.join(", ") },
    }));
  }, [
    isBrokenByEnvironment,
    selectedEnvApp,
    selectedEnvAppValues,
    selectedEnvWeb,
    selectedEnvWebValues,
    selectedChannelList,
    setFormValue,
  ]);

  return (
    <>
      <div className="my-3">
        <p className="text-sm">
          Do you want the channel list to be broken down by environment type?
        </p>
        <div className="w-full">
          <SingleSelectDropdown
            items={["Yes", "No"]}
            selectedItem={isBrokenByEnvironment}
            setSelectedItem={(value) => setIsBrokenByEnvironment(value)}
          />
        </div>
      </div>
      {isBrokenByEnvironment === "Yes" ? (
        <div className="flex gap-8">
          <div>
            <div className="my-3">
              <p className="text-sm">Select Environment</p>
              <div className="w-full">
                <SingleSelectDropdown
                  items={["App"]}
                  selectedItem={selectedEnvApp}
                  setSelectedItem={(value) => setSelectedEnvApp(value)}
                  disabled
                />
              </div>
            </div>
            <div className="my-3">
              <p className="text-sm">Select Channels List For App</p>
              <div className="w-full">
                <MultiSelectDropdown
                  items={options}
                  selectedItems={selectedEnvAppValues}
                  setSelectedItems={(value) => setSelectedEnvAppValues(value)}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="my-3">
              <p className="text-sm">Select Environment</p>
              <div className="w-full">
                <SingleSelectDropdown
                  items={["Web"]}
                  selectedItem={selectedEnvWeb}
                  setSelectedItem={(value) => setSelectedEnvWeb(value)}
                  disabled
                />
              </div>
            </div>
            <div className="my-3">
              <p className="text-sm">Select Channels List for Web</p>
              <div className="w-full">
                <MultiSelectDropdown
                  items={options}
                  selectedItems={selectedEnvWebValues}
                  setSelectedItems={(value) => setSelectedEnvWebValues(value)}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="my-3">
          <p className="text-sm">Select Channels List</p>
          <div className="w-full">
            <MultiSelectDropdown
              items={options}
              selectedItems={selectedChannelList}
              setSelectedItems={(value) => setSelectedChannelList(value)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ChannelPopup;
